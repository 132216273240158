import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalProductBadgesProps } from '..';

const SoldOutBadgeC = ({ children, ...props }: InternalProductBadgesProps) => {
    return (
        <Box p="4px 6px" borderRadius="4px" backgroundColor="#FFF2F0" {...props}>
            <StyledBodyText staticSize="12px" color="#D23B27" lineHeight="1">
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SoldOutBadgeC;

const StyledBodyText = styled(BodyText)`
    display: block;
`;
