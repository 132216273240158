import { Button, VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import { ShareLinkButtonInnerProps } from '..';

const ShareLinkButtonD: React.FC<ShareLinkButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled" shadow={theme.dropShadow.shadow3} p="8px 4px" minW="120px" borderRadius="4px">
            <VStack spacing="8px" w="100%">
                <Icon size={44}>Link</Icon>
                <Body2C color={theme.text.body1}>Copy URL</Body2C>
            </VStack>
        </Button>
    );
};

export default ShareLinkButtonD;
