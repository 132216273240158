import styled from '@emotion/styled';
import { IconStyleProps } from '..';
import { IconButton } from 'src/legacy/components/blockui';

const StyledIconDesignB = styled(IconButton)<IconStyleProps>`
    ${({ size }) => `
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12) ;
    background-color: #fff !important;
    border-radius: 3000px;
    
    .material-symbols-outlined {
        font-weight: 300;
    };
    `}
`;

export default StyledIconDesignB;
