import CustomIcon from 'src/legacy/components/common/custom-icon';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { ArrowButtonProps } from '..';

const ArrowButtonB: React.FC<ArrowButtonProps> = ({ color, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <StyledButton theme={theme} role="button" {...props}>
            <CustomIcon size="28px" color={theme.gray[700]}>
                chevron_right
            </CustomIcon>
        </StyledButton>
    );
};

export default ArrowButtonB;

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 300px;
    width: 34px;
    height: 34px;
    background: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
`;
