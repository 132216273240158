import { ButtonProps } from '@chakra-ui/react';
import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import { useErrorDrawer } from '@legacy/hooks/use-error-drawer';
import KakaoService, { KakaoMessageParams } from '@services/kakao.service';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ShareKakaoButtonA from './designA/share-kakao-button';
import ShareKakaoButtonB from './designB/share-kakao-button';
import ShareKakaoButtonC from './designC/share-kakao-button';
import ShareKakaoButtonD from './designD/share-kakao-button';

export interface ShareKakaoButtonProps extends Omit<ButtonProps, 'onClick' | 'children'> {
    params: Omit<KakaoMessageParams, 'jsKey' | 'templateid'>;
}

export interface ShareKakaoButtonInnerProps extends ShareKakaoButtonProps {
    onClick: () => void;
}

const ShareKakaoButton: React.FC<ShareKakaoButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ShareKakaoButtonInnerProps>>({
        [Design.DesignA]: ShareKakaoButtonA,
        [Design.DesignB]: ShareKakaoButtonB,
        [Design.DesignC]: ShareKakaoButtonC,
        [Design.DesignD]: ShareKakaoButtonD,
    });

    const { settings } = useHarmony();
    const showErrorDrawer = useErrorDrawer();

    const handleClick = () => {
        if (!KakaoService.isAvailable && !settings?.shareSetting?.platformConfig.kakao.jsKey) {
            showErrorDrawer('준비중입니다.');
            return;
        }

        KakaoService.sendLink({
            ...props.params,
            kakaoJsKey: settings?.shareSetting?.platformConfig.kakao.jsKey,
            templateId: settings?.shareSetting?.platformConfig.kakao.templateId || undefined,
        });
    };

    return <Component onClick={handleClick} {...props} />;
};

export default ShareKakaoButton;
