import { Box, HStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { CounterBarProps } from '..';

const CounterBarB: React.FC<CounterBarProps> = ({ currentCount, maxCount }) => {
    const { theme } = useHarmony();

    const defaultStyle = {
        h: '6px',
        borderRadius: '10px',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
    };

    return (
        <HStack spacing="6px">
            {[...new Array(maxCount)].map((_, i) => {
                return i === currentCount - 1 ? <Box bgColor={theme.gray[900]} w="28px" {...defaultStyle} /> : <Box bgColor={theme.gray[50]} w="6px" {...defaultStyle} />;
            })}
        </HStack>
    );
};

export default CounterBarB;
