import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import ComponentProduct from '@models/product/component-product';
import BodyText from 'src/legacy/components/common/text/body-text';
import { ProductCardDetailsProps } from '..';

const ProductCardDetailsC: React.FC<ProductCardDetailsProps> = ({ product, isDiscounted, lines, isEllipsisBrandName }) => {
    const { theme, designSettings } = useHarmony();
    const { currencyService } = useGlobalization();
    const { isAuth } = useCustomer();

    return (
        <Container>
            <BodyText size="15px" lineHeight="18px" color={theme.gray[800]} ellipsis lineClamp={lines ? lines : 3} wordBreak={'break-all'} whiteSpace={'normal'}>
                {product.showBrandOnProductCard && (
                    <Flex
                        display="inline-flex"
                        whiteSpace="pre-wrap"
                        wordBreak="break-all"
                        noOfLines={isEllipsisBrandName ? 1 : undefined}
                        lineHeight="inherit"
                    >{`[${product.brand}] `}</Flex>
                )}
                {product.title}
            </BodyText>
            <Price>
                {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                    <BodyText className="list-price" size={'16px'} color={theme.gray[500]} font="secondary" weight="thin">
                        {product.marketPriceString}
                    </BodyText>
                )}
                <DiscountPrice>
                    {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                        <BodyText size={'17px'} font="secondary" weight="bold">
                            {isDiscounted && product instanceof ComponentProduct ? product.minSellPriceAfterReduction : product.sellPriceString}
                            <BodyText size={'15px'}>{currencyService.currencySymbol}</BodyText>
                        </BodyText>
                    ) : (
                        <BodyText size={'17px'} font="secondary" weight="bold">
                            {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                        </BodyText>
                    )}
                    {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                        <BodyText size={'17px'} color={theme.primary.default} font="secondary" weight="bold">
                            {product.discountRate}
                            <BodyText size={'17px'} color={theme.primary.default} font="secondary">
                                %
                            </BodyText>
                        </BodyText>
                    )}
                </DiscountPrice>
            </Price>
        </Container>
    );
};

export default ProductCardDetailsC;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const Price = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .list-price {
        position: relative;
        display: inline-block;
        text-decoration: line-through;
    }
`;

const DiscountPrice = styled.div`
    display: flex;
    gap: 4px;
    align-items: flex-end;
`;
