import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import ComponentProduct from '@models/product/component-product';
import BodyText from 'src/legacy/components/common/text/body-text';
import { ProductCardDetailsProps } from '..';
import { useCustomer } from '@hooks/use-customer/use-customer';

const ProductCardDetailsA: React.FC<ProductCardDetailsProps> = ({ product, isDiscounted, cols, centered, isEllipsisBrandName }) => {
    const { theme, designSettings } = useHarmony();
    const { currencyService } = useGlobalization();
    const { isAuth } = useCustomer();

    return (
        <Container centered={centered}>
            <BodyText size="16px" color={theme.gray[800]} lineHeight="20px" ellipsis lineClamp={2}>
                {product.showBrandOnProductCard && (
                    <Flex
                        display="inline-flex"
                        color={theme.primary.default}
                        whiteSpace="pre-wrap"
                        wordBreak="break-all"
                        noOfLines={isEllipsisBrandName ? 1 : undefined}
                        lineHeight="inherit"
                    >{`[${product.brand}] `}</Flex>
                )}
                {product.title}
            </BodyText>
            <Price theme={theme}>
                <div>
                    {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                        <BodyText className="list-price" size={'15px'} color={theme.gray[300]} font="secondary" weight="thin">
                            {product.marketPriceString}
                        </BodyText>
                    )}
                    <DiscountPrice>
                        {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                            <DiscountPercent size={'17px'} color={theme.primary.default} font="secondary" weight="bold">
                                {product.discountRate}%
                            </DiscountPercent>
                        )}
                        {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                            <BodyText size={'17px'} font="secondary" weight="bold">
                                {isDiscounted && product instanceof ComponentProduct ? product.minSellPriceAfterReduction : product.sellPriceString}
                                <BodyText size={'14px'}>{currencyService.currencySymbol}</BodyText>
                            </BodyText>
                        ) : (
                            <BodyText size={'17px'} font="secondary" weight="bold">
                                {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                            </BodyText>
                        )}
                    </DiscountPrice>
                </div>
            </Price>
        </Container>
    );
};

export default ProductCardDetailsA;

export const Price = styled.div<{ centered?: boolean }>`
    ${({ centered }) => `
display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 6px;
    .list-price {
        position: relative;
        display: inline-block;
        text-decoration: line-through;
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: ${centered ? 'center' : 'justify-start'};
    }`}
`;

const DiscountPercent = styled(BodyText)`
    margin-right: 2px;
`;

const DiscountPrice = styled.div`
    margin-top: 4px;
    display: flex;
    gap: 2px;
    align-items: center;
`;

const Container = styled.div<{ centered?: boolean }>`
    ${({ centered }) => `
    span {
        text-align: ${centered ? 'center' : 'left'};
    }`}
`;
