import { Button, Center, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareKakaoButtonInnerProps } from '..';

const ShareKakaoButtonB: React.FC<ShareKakaoButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.12)" borderRadius="16px" w="72px" h="72px">
                    <CustomImage src={'/image/icon_kakao.png'} alt="kakao" width={40} height={40} layout="fixed" objectFit="contain" />
                </Center>
                <BodyText size="18px" lineHeight="22px">
                    Kakaotalk
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareKakaoButtonB;
