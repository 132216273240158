import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import ArrowButtonA from './designA/arrow-button';
import ArrowButtonB from './designB/arrow-button';

export interface ArrowButtonProps extends Omit<React.HTMLProps<HTMLDivElement>, 'as'> {
    color?: string;
    children?: ReactNode;
}

const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
    const Component = useDesignComponents<typeof ArrowButtonA | typeof ArrowButtonB>({
        [Design.DesignA]: ArrowButtonA,
        [Design.DesignB]: ArrowButtonB,
    });

    return <Component {...props} />;
};

export default ArrowButton;
