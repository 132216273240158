import { useGATracking } from '@hooks/use-ga-tracking';
import RoutesEnum from '@routes';
import { BaseProductCardProps } from '@type/theme';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import { useRouter } from 'next/router';
import { ComponentType, useCallback } from 'react';

export const withProductCardHOC = <T extends BaseProductCardProps>(ProductCardComponent: ComponentType<T>) => {
    const InnerComponent: React.FC<T> = (props) => {
        const router = useRouter();
        const { logEvent } = useGATracking();

        const handleClick = useCallback(() => {
            if (props.trackItemSelect) {
                logEvent('select_item', {
                    items: [
                        {
                            item_id: props.product.productId,
                            item_name: props.product.title,
                        },
                    ],
                });
            }

            if (props.isDisabled) {
                return;
            }

            if (!props.onClick) {
                router.push({ pathname: RoutesEnum.ProductDetail, query: { productId: props.product.id } });
                return;
            }

            props.onClick(props.product.id);
        }, [props, router]);

        const isDisabled = props.isDisabled || props.product.isSoldOut;

        return <ProductCardComponent {...props} handleClick={handleClick} isDisabled={isDisabled} />;
    };

    InnerComponent.displayName = getDisplayName(ProductCardComponent);
    return InnerComponent;
};
