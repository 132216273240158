import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import TextBadgeA from './designA/text-badge';
import TextBadgeB from './designB/text-badge';
import TextBadgeC from './designC/text-badge';

interface TextContentProps {
    textBadgeContent: string;
}

interface TextBadgeExternalProps {
    large?: boolean;
    hasDivider?: boolean;
    padding?: string;
}

export interface TextBadgeProps extends TextContentProps, TextBadgeExternalProps {}

const TextBadge: React.FC<TextBadgeExternalProps> = ({ ...props }) => {
    const { designSettings } = useHarmony();
    const Component = useDesignComponents<typeof TextBadgeA | typeof TextBadgeB>({
        [Design.DesignA]: TextBadgeA,
        [Design.DesignB]: TextBadgeB,
        [Design.DesignC]: TextBadgeC,
    });

    if (!designSettings?.isTextBadgeOn || !designSettings.textBadgeContent) {
        return <></>;
    }

    return <Component textBadgeContent={designSettings.textBadgeContent} {...props} />;
};

export default TextBadge;
