import CustomImage from 'src/legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { ProductStyleProps } from '..';

const StyledImage = styled(CustomImage)<ProductStyleProps>`
    ${({ disabled }) => `
    opacity: ${disabled ? 0.4 : 1};
  `}
`;

export default StyledImage;
