import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PartialAbstractedTextProps } from '../../../../components/abstracted-text';
import StrikeThroughD from './designD/strike-through';

export interface StrikeThroughProps extends PartialAbstractedTextProps {}

const StrikeThrough: React.FC<StrikeThroughProps> = (props) => {
    const Component = useDesignComponents<ComponentType<StrikeThroughProps>>({
        [Design.DesignD]: StrikeThroughD,
    });

    return <Component {...props} />;
};

export default StrikeThrough;
