import { useHarmony } from '@hooks/use-harmony';
import { ButtonLeftProps } from '..';
import PaginationButton from '../../pagination-button';

const ButtonLeftD: React.FC<ButtonLeftProps> = (props) => {
    const { theme } = useHarmony();

    return <PaginationButton {...props}>chevron_left</PaginationButton>;
};

export default ButtonLeftD;
