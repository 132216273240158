import RatioBox from '@components/ratio-box';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import { ProductCardImageInnerProps } from '..';

const ProductCardImageD: React.FC<ProductCardImageInnerProps> = ({ imageUrl, alt, isDisabled, ...props }) => {
    const { theme } = useHarmony();

    return (
        <RatioBox pos="relative" overflow="hidden" borderRadius="4px" border="1px solid" borderColor={theme.stroke.divider} opacity={isDisabled ? 0.4 : 1} {...props}>
            <CustomImage layout="fill" src={imageUrl} alt={alt} objectFit="cover" />
        </RatioBox>
    );
};

export default ProductCardImageD;
