import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { LinkProps } from 'next/link';
import { ComponentType } from 'react';
import FixedProductCardSectionD from './designD/fixed-product-card-section';

export interface FixedProductCardSectionProps {
    products: ComponentProduct[];
    onProductClick?: (productId: string) => void;
    viewMoreHref?: LinkProps['href'];
    type: 'L' | 'M';
    isDisabled?: boolean;
    trackItemSelect?: boolean;
}

const FixedProductCardSection: React.FC<FixedProductCardSectionProps> = (props) => {
    const Component = useDesignComponents<ComponentType<FixedProductCardSectionProps>>({
        [Design.DesignD]: FixedProductCardSectionD,
    });

    return <Component {...props} />;
};

export default FixedProductCardSection;
