import { VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { useTranslations } from 'next-intl';
import BodyText from 'src/legacy/components/common/text/body-text';
import { ProductPriceProps } from '..';
import { useSellPricePolicy } from '@hooks/use-sell-price-policy';
import { useCustomer } from '@hooks/use-customer/use-customer';

const ProductPriceB: React.FC<ProductPriceProps> = ({ product, compact = false, wishlist, onShareClick, ...props }) => {
    const { isAuth } = useCustomer();
    const { theme, designSettings } = useHarmony();
    const { currencyService } = useGlobalization();
    const t = useTranslations();
    const { getPriceWithUnitPolicy } = useSellPricePolicy();

    return (
        <VStack alignItems="flex-start" spacing="2px">
            {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                <ListPrice size={compact ? '14px' : '15px'} color={theme.gray[400]} font="secondary" lineHeight="14px">
                    {product.displayedMarketPriceString}
                </ListPrice>
            )}
            <DiscountPrice>
                {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                    <BodyText size={compact ? '17px' : '19px'} color={theme.primary.default} font="secondary" weight="bold" lineHeight="18px">
                        {product.discountRate}%
                    </BodyText>
                )}

                <BodyText size="15px" lineHeight="12px">
                    {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                        <>
                            {product.isSubscriptionSalesType &&
                                product.subscriptionInfo?.priceDisplayTypeIsMinSubPrice &&
                                `${t('common.pluralMonth', { month: product.subscriptionMonthlyUnit })}/`}
                            <BodyText size={compact ? '17px' : '20px'} font="secondary" weight="bold" lineHeight="18px" color={theme.gray[900]}>
                                {getPriceWithUnitPolicy(product.displayedSellPrice)}
                                <BodyText size={compact ? '14px' : '15px'} weight="medium" color="inherit">
                                    {currencyService.currencySymbol}{' '}
                                    {product.isSubscriptionSalesType &&
                                        product.subscriptionInfo?.priceDisplayTypeIsMinPurPrice &&
                                        product.isDiscountPerPaymentIntervalApplied &&
                                        `~`}
                                </BodyText>
                            </BodyText>
                        </>
                    ) : (
                        <BodyText size={compact ? '17px' : '20px'} font="secondary" weight="bold" lineHeight="18px" color={theme.gray[900]}>
                            {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                        </BodyText>
                    )}
                </BodyText>
            </DiscountPrice>
        </VStack>
    );
};

export default ProductPriceB;

export const ListPrice = styled(BodyText)`
    position: relative;
    display: inline-block;
    text-decoration: line-through;
`;

const DiscountPrice = styled.div`
    margin-top: 2px;
    display: flex;
    gap: 4px;
    align-items: center;
`;
