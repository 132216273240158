import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalSpecialPriceBadgeProps } from '..';

const SpecialPriceBadgeC = ({ children, ...props }: InternalSpecialPriceBadgeProps) => {
    return (
        <Box p="4px 6px" borderRadius="4px" backgroundColor="#DFEDFF" {...props}>
            <StyledBodyText staticSize="12px" color="#1C69D4" lineHeight="1">
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SpecialPriceBadgeC;

const StyledBodyText = styled(BodyText)`
    display: block;
`;
