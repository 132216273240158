import WishListButton from '@legacy/designs/wish-list-button';
import styled from '@emotion/styled';
import { WishListButtonPositionProps } from '..';

const WishListButtonPositionB = styled(WishListButton)<WishListButtonPositionProps>`
    ${({ disabled }) => `
        position: absolute;
        bottom: 12px;
        right: 12px;
        padding: 5px;

        .material-symbols-outlined {
            font-weight: 300;
        }
    `}
`;

export default WishListButtonPositionB;
