import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import { ProductPriceProps } from '..';
import { useCustomer } from '@hooks/use-customer/use-customer';

const ProductPriceC: React.FC<ProductPriceProps> = ({ product, compact = false, wishlist, isDiscounted, productDetail = false, ...props }) => {
    const { theme, designSettings } = useHarmony();
    const { currencyService } = useGlobalization();
    const { isAuth } = useCustomer();

    return (
        <BlockVStack spacing={compact ? '4px' : '6px'}>
            {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                <ListPrice size={compact ? '14px' : '18px'} color={theme.gray[500]} font="secondary" weight={compact ? 'thin' : 'regular'}>
                    {product.marketPriceString}
                </ListPrice>
            )}
            <BlockHStack spacing="4px">
                {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                    <BodyText size={compact ? '15px' : '20px'} font="secondary" weight="bold">
                        {isDiscounted ? product.minSellPriceAfterReduction : product.sellPriceString}
                        <BodyText size={compact ? '13px' : '18px'}>{currencyService.currencySymbol}</BodyText>
                    </BodyText>
                ) : (
                    <BodyText size={compact ? '15px' : '20px'} font="secondary" weight="bold">
                        {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                    </BodyText>
                )}
                {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                    <BodyText size={compact ? '15px' : '20px'} color={theme.primary.default} font="secondary" weight="bold">
                        {product.discountRate}
                        <BodyText size="inherit" color="inherit" font="secondary" weight="regular">
                            %
                        </BodyText>
                    </BodyText>
                )}
            </BlockHStack>
        </BlockVStack>
    );
};

export default ProductPriceC;

const ListPrice = styled(BodyText)`
    position: relative;
    display: inline-block;
    text-decoration: line-through;
`;
