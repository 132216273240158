import { Box, Center } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { CounterBarProps } from '..';

const CounterBarD: React.FC<CounterBarProps> = ({ currentCount, maxCount }) => {
    const { theme } = useHarmony();

    return (
        <Center w="100%">
            <Box pos="relative" w="64px" h="4px" bgColor={theme.background.gray100} borderRadius="full">
                <Box
                    pos="absolute"
                    w={`calc(100% / ${maxCount})`}
                    h="100%"
                    bgColor={theme.gray[600]}
                    borderRadius="full"
                    transform="auto"
                    translateX={`calc(100% * ${currentCount - 1})`}
                    transitionProperty="transform"
                    transitionDuration="0.2s"
                    transitionTimingFunction="ease-in-out"
                />
            </Box>
        </Center>
    );
};

export default CounterBarD;
