import { ButtonProps } from '@chakra-ui/react';
import { useClipBoard } from '@hooks/use-clip-board';
import { useDesignComponents } from '@hooks/use-design-components';
import safeWindow from '@services/safe-window.service';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ShareLinkButtonA from './designA/share-link-button';
import ShareLinkButtonB from './designB/share-link-button';
import ShareLinkButtonC from './designC/share-link-button';
import ShareLinkButtonD from './designD/share-link-button';

export interface ShareLinkButtonProps extends Omit<ButtonProps, 'onClick' | 'children'> {
    url: string;
    title?: string;
    callback?: () => void;
}

export interface ShareLinkButtonInnerProps extends ShareLinkButtonProps {
    onClick: () => void;
}

const ShareLinkButton: React.FC<ShareLinkButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ShareLinkButtonInnerProps>>({
        [Design.DesignA]: ShareLinkButtonA,
        [Design.DesignB]: ShareLinkButtonB,
        [Design.DesignC]: ShareLinkButtonC,
        [Design.DesignD]: ShareLinkButtonD,
    });

    const copyToClipBoard = useClipBoard();

    const handleClick = () => {
        if (!!safeWindow) {
            copyToClipBoard(props.url).finally(() => {
                navigator.share?.({
                    url: props.url,
                    title: props.title || document.title,
                });
            });
        }

        props.callback?.();
    };

    return <Component onClick={handleClick} {...props} />;
};

export default ShareLinkButton;
