import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PaginationButtonProps } from '../pagination-button';
import ButtonLeftD from './designD/button-left';
import ButtonLeftA from './designA/button-left';

export interface ButtonLeftProps extends Omit<PaginationButtonProps, 'children'> {}

const ButtonLeft: React.FC<ButtonLeftProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ButtonLeftProps>>({
        [Design.DesignA]: ButtonLeftA,
        [Design.DesignD]: ButtonLeftD,
    });

    return <Component {...props} />;
};

export default ButtonLeft;
