import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { PaginationButtonProps } from '../pagination-button';
import ButtonRightD from './designD/button-right';
import ButtonRightA from './designA/button-right';

export interface ButtonRightProps extends Omit<PaginationButtonProps, 'children'> {}

const ButtonRight: React.FC<ButtonRightProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ButtonRightProps>>({
        [Design.DesignA]: ButtonRightA,
        [Design.DesignD]: ButtonRightD,
    });

    return <Component {...props} />;
};

export default ButtonRight;
