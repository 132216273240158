import styled from '@emotion/styled';
import { FixedProductListContainerProps } from '..';

const FixedProductListContainerA = styled.div<FixedProductListContainerProps>`
    display: grid;
    gap: 14px 8px;
    place-content: center center;
    width: 100%;
    padding: 8px 16px 0 16px;
    overflow: hidden;

    ${({ cols }) => `
      grid-template-columns: repeat(${cols}, 1fr);
    `}
`;

export default FixedProductListContainerA;
