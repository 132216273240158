import { Box } from '@chakra-ui/react';
import { AgeVerificationIcon } from '@designs/grouped/common/age-verification-icon';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import WishListButton from '@legacy/designs/wish-list-button';
import { IThemeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { BlockVStack } from 'src/legacy/components/blockui';
import RoutesEnum from 'src/routes/routes';
import { SwipeListProductProps } from '..';

const SwipeListProductDesignB: React.FC<SwipeListProductProps> = ({ product, ...props }) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <ImageListProduct theme={theme} {...props}>
            <Link
                href={{
                    pathname: RoutesEnum.ProductDetail,
                    query: { productId: product.id },
                }}
            >
                <div>
                    <BlockVStack spacing="8px">
                        <div className="image">
                            {product.image && <ProductCardImage disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                            <WishlistContainer>
                                <WishListButton size="18px" product={product} color={theme.primary.default} />
                            </WishlistContainer>
                            {product.isAgeVerificationRequired && (
                                <Box pos="absolute" top="12px" right="12px">
                                    <AgeVerificationIcon />
                                </Box>
                            )}
                        </div>
                        <ProductCardDetails product={product} lines={2} />
                    </BlockVStack>
                </div>
            </Link>
        </ImageListProduct>
    );
};

export default SwipeListProductDesignB;

const ImageListProduct = styled('div')<IThemeProps>`
    ${({ theme }) => `
    width: 144px;
    margin-right: 16px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    &:nth-last-of-type(1) {
        margin-right: 0px;
    }
    .image {
        position: relative;
        width: 144px;
        height: 144px;
        font-family: ${theme.font.default.light};
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        border: solid 1px ${theme.gray[200]} !important;
        border-radius: 16px;
        overflow: hidden;
    }
    .name {
      margin-top: 8px;
      font-family: ${theme.font.default.regular};
      font-size: 0.75rem;
      letter-spacing: -0.04em;
      color: ${theme.gray[600]};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .bold {
        width: 100%;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .price {
      margin-top: 6px;
      line-height: 1;
      .list-price {
        position: relative;
        display: inline-block;
        color: ${theme.gray[50]};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.75rem;
        letter-spacing: -0.03em;
        &:after {
          content: "";
          position: absolute;
          left: 0px;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: ${theme.gray[50]};
        }
      }
      .discount-price {
        color: ${theme.gray[900]};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.9375rem;
        letter-spacing: -0.03em;
      }
      .discount-percent {
        margin-right: 2px;
        color: ${theme.primary.default};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.9375rem;
        letter-spacing: -0.03em;
      }
      .unit {
        color: ${theme.gray[900]};
        font-family: ${theme.font.default.regular};
        font-size: 0.6875rem;
        letter-spacing: -0.03em;
      }
    }
    `}
`;

const WishlistContainer = styled.div`
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
`;
