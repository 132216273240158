import { useHarmony } from '@hooks/use-harmony';
import { PaginationButtonProps } from '..';
import IconButton from '@designs/primitives/common/icon-button';

const PaginationButtonD: React.FC<PaginationButtonProps> = ({ isDisabled, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <IconButton
            variant={'solid-gray'}
            w={'28px'}
            maxW={'28px'}
            h={'28px'}
            p={'0px'}
            borderRadius={'full'}
            boxShadow={'0px 0px 4px 0px rgba(0, 0, 0, 0.10)'}
            color={isDisabled ? theme.icon.gray400 : theme.gray[800]}
            display={'inline-flex'}
            justifyContent={'center'}
            isDisabled={isDisabled}
            _disabled={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)' }}
            {...props}
        >
            {children}
        </IconButton>
    );
};

export default PaginationButtonD;
