import styled from '@emotion/styled';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import WishListButton from '@legacy/designs/wish-list-button';
import BodyText from 'src/legacy/components/common/text/body-text';
import ShareButton from 'src/legacy/components/product-detail/share-button';
import { ProductPriceProps } from '..';
import { useCustomer } from '@hooks/use-customer/use-customer';

const ProductPriceA: React.FC<ProductPriceProps> = ({ product, compact = false, wishlist, isDiscounted, onShareClick, productDetail = false, ...props }) => {
    const { theme, designSettings } = useHarmony();
    const { currencyService } = useGlobalization();
    const { isAuth } = useCustomer();

    return (
        <Price theme={theme}>
            <div>
                {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                    <ListPrice size={compact ? '15px' : '16px'} color={theme.gray[300]} font="secondary" weight="thin">
                        {product.marketPriceString}
                    </ListPrice>
                )}
                <DiscountPrice>
                    {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                        <DiscountPercent size={compact ? '17px' : '20px'} color={theme.primary.default} font="secondary" weight="bold">
                            {product.discountRate}%
                        </DiscountPercent>
                    )}
                    <BodyText size={compact ? '17px' : '20px'} font="secondary" weight="bold">
                        {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                            <>
                                {isDiscounted ? product.minSellPriceAfterReduction : product.sellPriceString}
                                <BodyText size={compact ? '14px' : '16px'}>{currencyService.currencySymbol}</BodyText>
                            </>
                        ) : (
                            designSettings?.guestUserProductCardSetting.alternativeTextForPrice
                        )}
                    </BodyText>
                </DiscountPrice>
            </div>
            {!compact && (
                <IconWrapper>
                    <ShareButton product={product} />
                    {wishlist && <WishListButton color={theme.gray[300]} dropShadow={false} size="25px" product={product} padding="0" />}
                </IconWrapper>
            )}
        </Price>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export default ProductPriceA;

export const Price = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const ListPrice = styled(BodyText)`
    position: relative;
    display: inline-block;
    text-decoration: line-through;
`;

const DiscountPercent = styled(BodyText)`
    margin-right: 2px;
`;

const DiscountPrice = styled.div`
    margin-top: 4px;
    display: flex;
    gap: 2px;
    align-items: center;
`;
