import CustomImage from 'src/legacy/components/common/custom-image';
import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { ProductStyleProps } from '..';

const StyledImage = styled(CustomImage)<IThemeProps & ProductStyleProps>`
    ${({ radius, disabled }) => `
    opacity: ${disabled ? 0.4 : 1};
    border-radius: ${radius || 12}px;
  `}
`;

export default StyledImage;
