import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { BaseProductCardProps } from '@type/theme';
import { ComponentType } from 'react';
import { withProductCardHOC } from '../with-product-card-hoc';
import FixedProductCardD from './designD/fixed-product-card';

export interface FixedProductCardProps extends BaseProductCardProps {
    isEllipsisBrandName?: boolean;
}

const FixedProductCard: React.FC<FixedProductCardProps> = withProductCardHOC(({ isEllipsisBrandName = true, ...props }) => {
    const Component = useDesignComponents<ComponentType<FixedProductCardProps>>({
        [Design.DesignD]: FixedProductCardD,
    });

    return <Component {...props} isEllipsisBrandName={isEllipsisBrandName} />;
});

export default FixedProductCard;
