import styled from '@emotion/styled';
import { Badge } from '@styles/styled-components';
import { IThemeProps } from '@type/common';

const FreeShippingBadgeC = styled(Badge)<IThemeProps>`
    width: 55px;
    height: 20px;

    ${({ theme }) => `
      background-color: ${theme.gray[50]};
      border-radius: 4px;
      padding: 4px 6px;
    `}
`;

export default FreeShippingBadgeC;
