import ShareKakaoButton from '@designs/grouped/common/share-kakao-button';
import ShareLinkButton from '@designs/grouped/common/share-link-button';
import { css } from '@emotion/react';
import { useHarmony } from '@hooks/use-harmony';
import ComponentProduct from '@models/product/component-product';
import safeWindow from '@services/safe-window.service';
import { useTranslations } from 'next-intl';
import { BlockHStack } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import RoundedDrawer from 'src/legacy/components/common/rounded-drawer';
import BodyText from 'src/legacy/components/common/text/body-text';
import KakaoScript from 'src/legacy/components/scripts/kakao-script';

interface ShareDrawerProps {
    open: boolean;
    onClose: () => void;
    product: ComponentProduct;
}

const ShareDrawer: React.FC<ShareDrawerProps> = ({ open, onClose, product }) => {
    const t = useTranslations();
    const { theme } = useHarmony();

    return (
        <RoundedDrawer open={open} onClose={onClose}>
            <KakaoScript />
            <div
                css={css`
                    padding: 0px 16px 60px 16px;
                    position: relative;
                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 88px;
                        height: 88px;
                        margin-right: 24px;
                        border-radius: 12px;
                        cursor: pointer;
                        background-color: ${theme.gray[50]};
                        &:nth-last-of-type(1) {
                            margin-right: 0px;
                        }
                    }
                `}
            >
                <div>
                    <BlockHStack alignItems="center" align="center" mt="24px" mb="32px">
                        <BodyText weight="bold" size="16px">
                            {t('common.share')}
                        </BodyText>
                    </BlockHStack>
                </div>
                <BlockHStack align="center" spacing="sm">
                    <ShareLinkButton url={safeWindow?.location.href || ''} callback={onClose} />
                    <ShareKakaoButton
                        params={{
                            title: product.title,
                            imageUrl: product.image,
                            linkUrl: safeWindow?.location.href || '',
                            buttonLabel: t('common.kakaoButton'),
                        }}
                    />
                </BlockHStack>
            </div>
            <div
                css={css`
                    position: absolute;
                    top: 14px;
                    right: 16px;
                `}
            >
                <CustomIconButton className="icon" size="25px" onClick={onClose}>
                    close
                </CustomIconButton>
            </div>
        </RoundedDrawer>
    );
};

export default ShareDrawer;
