import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { useSellPricePolicy } from '@hooks/use-sell-price-policy';
import ProductBadges, { Direction } from '@legacy/components/product/product-badges';
import { ListPrice } from '@legacy/designs/product-price';
import TextBadge from '@legacy/designs/text-badge';
import { LineClipText } from '@styles/styled-components';
import { useTranslations } from 'next-intl';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import BodyText from 'src/legacy/components/common/text/body-text';
import { ProductCardDetailsProps } from '..';

const ProductCardDetailsB: React.FC<ProductCardDetailsProps> = ({ product, centered = false, cols, lines, isEllipsisBrandName }) => {
    const { theme, designSettings } = useHarmony();
    const t = useTranslations();
    const { currencyService } = useGlobalization();
    const { getPriceWithUnitPolicy } = useSellPricePolicy();
    const { isAuth } = useCustomer();

    return (
        <BlockVStack spacing="3px" align={centered ? 'center' : 'distribute'}>
            <LineClipText size="15px" lineHeight="18px" textAlign={centered ? 'center' : 'left'} lines={lines} letterSpacing={'-0.01em'} weight={'medium'}>
                {product.showBrandOnProductCard && (
                    <Flex
                        display="inline-flex"
                        color={theme.primary.default}
                        fontWeight={'bold'}
                        whiteSpace="pre-wrap"
                        wordBreak="break-all"
                        noOfLines={isEllipsisBrandName ? 1 : undefined}
                        lineHeight="inherit"
                        mb="4px"
                    >
                        {`[${product.brand}] `}
                    </Flex>
                )}
                {product.title}
            </LineClipText>
            {(isAuth ? designSettings?.isShowMarketPrice : designSettings?.isGuestShowMarketPrice) && product.hasDiscount && (
                <ListPrice size="14px" color={theme.gray[400]}>
                    {product.displayedMarketPriceString}
                </ListPrice>
            )}

            <PriceContainer align={cols === 1 ? 'left' : 'distribute'} spacing="4px" alignItems="flex-end">
                {isAuth || designSettings?.guestUserProductCardSetting.isSalesPriceDisplayed ? (
                    <BlockHStack spacing="2px" alignItems="center">
                        {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinSubPrice && (
                            <BodyText size="15px" lineHeight="12px" textAlign={centered ? 'center' : 'left'}>
                                {`${t('common.pluralMonth', { month: product.subscriptionMonthlyUnit })}/`}
                            </BodyText>
                        )}
                        <BodyText>
                            <BodyText font="secondary" color={theme.gray[900]} weight="bold" size="18px" lineHeight="18px" textAlign={centered ? 'center' : 'left'}>
                                {getPriceWithUnitPolicy(product.displayedSellPrice)}
                            </BodyText>

                            <BodyText size="15px" lineHeight="14px" textAlign={centered ? 'center' : 'left'} weight="bold">
                                {currencyService.currencySymbol} {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinPurPrice && `~`}
                            </BodyText>
                        </BodyText>
                    </BlockHStack>
                ) : (
                    <BodyText font="secondary" color={theme.gray[900]} weight="bold" size="18px" lineHeight="18px" textAlign={centered ? 'center' : 'left'}>
                        {designSettings?.guestUserProductCardSetting.alternativeTextForPrice}
                    </BodyText>
                )}

                {(isAuth ? designSettings?.isShowDiscountRate : designSettings?.isGuestShowDiscountRate) && product.hasDiscount && (
                    <BodyText weight="bold" font="secondary" size="18px" lineHeight="18px" color={theme.primary.default}>
                        {product.discountRate}%
                    </BodyText>
                )}
            </PriceContainer>
            <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} direction={Direction.Horizontal} />
            <TextBadge hasDivider />
        </BlockVStack>
    );
};

const PriceContainer = styled(BlockHStack)`
    flex-wrap: wrap;
`;

export default ProductCardDetailsB;
