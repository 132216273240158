import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { ArrowButtonProps } from '..';

const ArrowButtonA: React.FC<ArrowButtonProps> = ({ color, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <StyledButton theme={theme} role="button" {...props}>
            <BodyText size="14px" color={color || theme.gray[600]} weight="thin">
                {children}
            </BodyText>
            <CustomIcon size="14px" color={color || theme.gray[600]}>
                arrow_forward
            </CustomIcon>
        </StyledButton>
    );
};

export default ArrowButtonA;

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
`;
