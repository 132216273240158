import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import SpecialPriceBadgeA from './designA/special-price-badge';
import SpecialPriceBadgeB from './designB/special-price-badge';
import SpecialPriceBadgeC from './designC/special-price-badge';

interface SpecialPriceBadgeProps {}

export interface InternalSpecialPriceBadgeProps extends SpecialPriceBadgeProps, IThemeProps {
    children: ReactNode;
}

const SpecialPriceBadge: React.FC<SpecialPriceBadgeProps> = ({ ...props }) => {
    const { theme } = useHarmony();
    const Component = useDesignComponents<typeof SpecialPriceBadgeA | typeof SpecialPriceBadgeB | typeof SpecialPriceBadgeC>({
        [Design.DesignA]: SpecialPriceBadgeA,
        [Design.DesignB]: SpecialPriceBadgeB,
        [Design.DesignC]: SpecialPriceBadgeC,
    });

    const t = useTranslations();

    return (
        <Component {...props} theme={theme}>
            {t('common.special')}
        </Component>
    );
};

export default SpecialPriceBadge;
