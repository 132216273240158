import { useDesignComponents } from '@hooks/use-design-components';

import { RatioBoxProps } from '@components/ratio-box';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ProductCardImageD from './designD/product-card-image';

interface ProductCardImageProps extends RatioBoxProps {
    imageUrl?: string;
    alt?: string;
    isDisabled?: boolean;
}

export interface ProductCardImageInnerProps extends ProductCardImageProps {
    imageUrl: string;
}

const ProductCardImage: React.FC<ProductCardImageProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ProductCardImageInnerProps>>({
        [Design.DesignD]: ProductCardImageD,
    });

    if (!props.imageUrl) {
        return <></>;
    }

    return <Component imageUrl={props.imageUrl} {...props} />;
};

export default ProductCardImage;
