import BodyText from 'src/legacy/components/common/text/body-text';
import { Divider } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { TextBadgeProps } from '..';

const TextBadgeB: React.FC<TextBadgeProps> = ({ large, textBadgeContent, hasDivider, padding = '4px 0 0 0' }) => {
    const { theme } = useHarmony();
    return (
        <PaddedContainer padding={padding}>
            {hasDivider && <Divider />}
            <BodyText size={large ? '19px' : '15px'} color={theme.gray[600]} lineHeight={'15px'} weight="medium">
                {textBadgeContent}
            </BodyText>
        </PaddedContainer>
    );
};

export default TextBadgeB;
