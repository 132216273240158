import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalProductBadgesProps } from '..';

const SoldOutBadgeB = ({ children, ...props }: InternalProductBadgesProps) => {
    return (
        <Box borderRadius="6px" p="3px" backgroundColor="black" boxShadow="0px 0px 3px rgba(0, 0, 0, 0.2)" {...props}>
            <StyledBodyText size="14px" color="white" weight="bold" lineHeight="1" textAlign="center">
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SoldOutBadgeB;

const StyledBodyText = styled(BodyText)`
    display: block;
    min-width: 24px;
`;
