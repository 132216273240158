import { Button, Center, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareKakaoButtonInnerProps } from '..';

const ShareKakaoButtonC: React.FC<ShareKakaoButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center border="1px solid" borderColor={theme.gray[100]} borderRadius="16px" w="72px" h="72px">
                    <CustomImage src={'/image/icon_kakao.png'} alt="kakao" width={40} height={40} layout="fixed" objectFit="contain" />
                </Center>
                <BodyText size="16px" lineHeight="20px" color={theme.gray[800]}>
                    Kakaotalk
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareKakaoButtonC;
