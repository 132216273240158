import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import BodyText from 'src/legacy/components/common/text/body-text';
import { SubmenuProps, SubmenuStyle } from '../submenu';
import ThemeUtils from '@utils/theme.utils';
import { useDragScroll } from '@hooks/use-mouse-drag';

const SubmenuC: React.FC<SubmenuProps> = ({ items, index, onSelect, secondary = false, padding, borderBottom, minWidth, ...props }) => {
    const { theme } = useHarmony();
    const dragScroll = useDragScroll();

    return (
        <Container theme={theme} padding={padding} borderBottom={borderBottom} {...props} {...dragScroll}>
            {items.map((item, i) => {
                const isActive = i === index;
                return (
                    <ButtonBox key={i} theme={theme}>
                        <Chip theme={theme} isActive={isActive}>
                            <BodyText
                                size="16px"
                                lineHeight="1"
                                color={isActive ? theme.white : theme.gray[600]}
                                weight={isActive ? 'bold' : 'regular'}
                                letterSpacing={isActive ? '-0.02rem' : '-0.01rem'}
                                onClick={() => onSelect(i)}
                            >
                                {item}
                            </BodyText>
                        </Chip>
                    </ButtonBox>
                );
            })}
        </Container>
    );
};

export default SubmenuC;

const Container = styled.div<IThemeProps & SubmenuStyle>`
    ${({ justifyContent = 'flex-start', sticky = false, top = '50px', theme, gap, padding, borderBottom, margin, backgroundColor }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    gap: ${gap || 4}px;
    padding: ${padding || '0 16px'};
    margin: ${margin};
    flex-wrap: nowrap;
    overflow-x: auto;
    user-select: none;
    background-color: ${backgroundColor || theme.white};
    ${
        sticky &&
        `
        position: sticky;
        top: ${typeof top === 'string' ? top : `${top}px`};
        left: 0;
        z-index: ${ThemeUtils.zIndex.header};
    `
    }
    ${borderBottom && `border-bottom: 1px solid ${theme.gray[50]};`}
  `}
`;

const ButtonBox = styled.button<IThemeProps>`
    height: 48px;

    ${({ theme }) => `
    background-color: ${theme.white};
  `}
`;

const Chip = styled.div<IThemeProps & { isActive: boolean }>`
    width: fit-content;
    height: 32px;
    display: flex;
    padding: 8px 12px;
    border-radius: 100px;
    white-space: nowrap;

    ${({ isActive, theme }) => `
    background-color: ${isActive ? theme.gray[900] : theme.gray[50]};
  `}
`;
