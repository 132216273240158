import { ISubscriptionInfoDto, SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { ChannelId, ImageUrl, ProductId, UserId } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import { IWishlistItemDto } from './responseDto/wishlist-item.dto';

class WishlistItem {
    private static _WISHLIST_ID = 'wishlist-id';

    private _id: number;
    private _brand: string;
    private _brandId: string | null;
    private _channelId: ChannelId;
    private _productId: ProductId;
    private _discountRate: number;
    private _marketPrice: number;
    private _minSellPrice: number;
    private _minPurPrice: number;
    private _timesaleAppliedNow: boolean;
    private _title: string;
    private _userId: UserId;
    private _images: ImageUrl[];
    private _salesType: SalesTypeEnum;
    private _subscriptionInfo: ISubscriptionInfoDto | null;

    constructor(wishlistDto: IWishlistItemDto) {
        this._id = wishlistDto.id;
        this._brand = wishlistDto.brand;
        this._brandId = wishlistDto.brandId;
        this._channelId = wishlistDto.channelId;
        this._productId = wishlistDto.productId;
        this._discountRate = wishlistDto.discountRate;
        this._marketPrice = wishlistDto.marketPrice;
        this._minSellPrice = wishlistDto.minSellPrice;
        this._minPurPrice = wishlistDto.minPurPrice;
        this._timesaleAppliedNow = wishlistDto.timesaleAppliedNow;
        this._title = wishlistDto.title;
        this._userId = wishlistDto.userId;
        this._images = wishlistDto.images;
        this._salesType = wishlistDto.salesType;
        this._subscriptionInfo = wishlistDto.subscriptionInfo;
    }

    static getWishlistId(): ProductId | null {
        return localStorage.getItem(this._WISHLIST_ID);
    }

    static setWishlistId(productId: ProductId): void {
        localStorage.setItem(this._WISHLIST_ID, productId);
    }

    static deleteWishlistId(): void {
        localStorage.removeItem(this._WISHLIST_ID);
    }

    get wishlistId(): number {
        return this._id;
    }

    /**Returns the product id for the purpose of extending types in shared components */
    get productId(): ProductId {
        return this._productId;
    }

    get image(): ImageUrl {
        return this._images[0];
    }

    get brand(): string {
        return this._brand;
    }

    get brandId(): string | null {
        return this._brandId;
    }

    get title(): string {
        return this._title;
    }

    get minSellPrice(): number {
        return this._minSellPrice;
    }

    get minSellPriceString(): string {
        return ConversionUtils.numberToString(this._minSellPrice);
    }

    get minPurPrice(): number {
        return this._minPurPrice;
    }

    get discountRate(): number {
        if (!this._discountRate) return 0;
        return Math.ceil(this._discountRate);
    }

    get hasDiscount(): boolean {
        return this.discountRate > 0;
    }

    get sellPrice(): number {
        return Math.floor(this._minSellPrice);
    }

    get sellPriceString(): string {
        return ConversionUtils.numberToString(this.sellPrice);
    }

    get marketPrice(): number {
        return Math.floor(this._marketPrice);
    }

    get marketPriceString(): string {
        return ConversionUtils.numberToString(Math.floor(this._marketPrice));
    }

    get timesaleAppliedNow(): boolean {
        return this._timesaleAppliedNow;
    }

    get salesType(): SalesTypeEnum {
        return this._salesType;
    }

    get isSubscriptionSalesType(): boolean {
        return this._salesType === SalesTypeEnum.Subscription || this._salesType === SalesTypeEnum.Hybrid;
    }

    get isNormalSalesType(): boolean {
        return this._salesType === SalesTypeEnum.Normal;
    }

    get subscriptionInfo(): ISubscriptionInfoDto | null {
        return this._subscriptionInfo;
    }
}

export default WishlistItem;
