import { useDesignComponents } from '@hooks/use-design-components';
import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import SwipeListProductA from './designA/swipe-list-product';
import SwipeListProductB from './designB/swipe-list-product';
import SwipeListProductC from './designC/swipe-list-product';

export interface SwipeListProductProps {
    product: ComponentProduct;
    onClick?: () => void;
}

const SwipeListProduct: React.FC<SwipeListProductProps> = (props) => {
    const Component = useDesignComponents<typeof SwipeListProductA | typeof SwipeListProductB | typeof SwipeListProductC>({
        [Design.DesignA]: SwipeListProductA,
        [Design.DesignB]: SwipeListProductB,
        [Design.DesignC]: SwipeListProductC,
    });
    return <Component {...props} />;
};

export default SwipeListProduct;
