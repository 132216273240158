import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalSpecialPriceBadgeProps } from '..';

const SpecialPriceBadgeB = ({ children, theme, ...props }: InternalSpecialPriceBadgeProps) => {
    return (
        <Box
            borderRadius="6px"
            p="3px"
            backgroundColor={theme.primary.lighten[200]}
            boxShadow="0px 0px 3px rgba(0, 0, 0, 0.2)"
            {...props}
            border={`1px solid ${theme.primary.default}`}
        >
            <StyledBodyText size="14px" color={theme.primary.darken[100]} weight="bold" lineHeight="1" textAlign="center">
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SpecialPriceBadgeB;

const StyledBodyText = styled(BodyText)`
    display: block;
    min-width: 24px;
`;
