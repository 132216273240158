import { Button, Center, VStack } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareKakaoButtonInnerProps } from '..';

const ShareKakaoButtonA: React.FC<ShareKakaoButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center bg={theme.gray[50]} borderRadius="12px" w="88px" h="88px">
                    <CustomImage src={'/image/icon_kakao.png'} alt="kakao" width={48} height={48} layout="fixed" objectFit="contain" />
                </Center>
                <BodyText color={theme.gray[800]} size="17px">
                    Kakaotalk
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareKakaoButtonA;
