import BodyText from 'src/legacy/components/common/text/body-text';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { TextBadgeProps } from '..';

const TextBadgeC: React.FC<TextBadgeProps> = ({ large, padding, textBadgeContent }) => {
    const { theme } = useHarmony();
    return (
        <PaddedContainer padding={padding ? padding : large ? '12px 0 0 0' : '8px 0 0 0'}>
            <BodyText size={large ? '16px' : '14px'} color={large ? theme.gray[500] : theme.gray[600]} lineHeight={'1'}>
                {textBadgeContent}
            </BodyText>
        </PaddedContainer>
    );
};

export default TextBadgeC;
