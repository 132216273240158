import { useDesignComponents } from '@hooks/use-design-components';

import FreeShippingBadge from '@legacy/designs/free-shipping-badge';
import SoldOutBadge from '@legacy/designs/sold-out-badge';
import SpecialPriceBadge from '@legacy/designs/special-price-badge';
import { BlockHStack, BlockVStack } from 'src/legacy/components/blockui';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { useTranslations } from 'next-intl';

export enum Direction {
    Vertical = 'vertical',
    Horizontal = 'horizontal',
}

interface ProductBadgesProps {
    isSoldOut: boolean;
    isSpecial: boolean;
    isFreeShipping?: boolean;
    direction?: Direction;
}

const ProductBadges: React.FC<ProductBadgesProps> = ({ isSoldOut, isSpecial, isFreeShipping, direction = Direction.Vertical }) => {
    const t = useTranslations();
    const { designSettings } = useHarmony();

    const spacing = useDesignComponents<string>({
        [Design.DesignA]: '4px',
        [Design.DesignB]: '4px',
        [Design.DesignC]: '2px',
    });

    return (
        <>
            {direction === Direction.Vertical ? (
                <BlockVStack spacing="none">
                    {designSettings?.isStockBadgeOn && isSoldOut && <SoldOutBadge>{t('common.soldOut')}</SoldOutBadge>}
                    {isSpecial && <SpecialPriceBadge />}
                </BlockVStack>
            ) : (
                <BlockHStack spacing={spacing}>
                    {designSettings?.isStockBadgeOn && isSoldOut && <SoldOutBadge>{t('common.soldOut')}</SoldOutBadge>}
                    {isSpecial && <SpecialPriceBadge />}
                    {isFreeShipping && <FreeShippingBadge />}
                </BlockHStack>
            )}
        </>
    );
};

export default ProductBadges;
