import { Box } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import { useHarmony } from '@hooks/use-harmony';
import { WishlistButtonInnerProps } from '..';

const WishlistButtonD: React.FC<WishlistButtonInnerProps> = ({ onClick, optimisticLiked }) => {
    const { theme } = useHarmony();

    return (
        <Box
            pos="relative"
            width="36px"
            height="36px"
            as="button"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }}
        >
            <Box
                pos="absolute"
                top={'1px'}
                left={'1px'}
                p="8px"
                __css={{
                    div: {
                        filter: `drop-shadow(${theme.dropShadow.wishlist})`,
                        textShadow: optimisticLiked
                            ? `-1px 0px ${theme.stroke.divider}, 0px 1px ${theme.stroke.divider}, 1px 0px ${theme.stroke.divider}, 0px -1px ${theme.stroke.divider}`
                            : 'none',
                    },
                }}
            >
                <Icon size={18} color={!!optimisticLiked ? theme.icon.secondary : theme.background.white}>
                    favorite
                </Icon>
            </Box>
        </Box>
    );
};

export default WishlistButtonD;
