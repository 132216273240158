import { useDesignComponents } from '@hooks/use-design-components';

import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import BodyText from 'src/legacy/components/common/text/body-text';
import { useTranslations } from 'next-intl';
import FreeShippingBadgeC from './designC/free-shipping-badge';

export interface ProductBadgesProps {}

const FreeShippingBadge: React.FC<ProductBadgesProps> = () => {
    const t = useTranslations();
    const { theme } = useHarmony();
    const Component = useDesignComponents<typeof FreeShippingBadgeC>({
        [Design.DesignC]: FreeShippingBadgeC,
    });

    const TextColor = useDesignComponents<string>({
        [Design.DesignC]: theme.gray[700],
    });

    return (
        <Component theme={theme}>
            <BodyText size="14px" color={TextColor}>
                {t('product.freeShipping')}
            </BodyText>
        </Component>
    );
};

export default FreeShippingBadge;
