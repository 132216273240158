import styled from '@emotion/styled';
import CustomIcon from '@legacy/components/common/custom-icon';
import ThemeUtils from '@utils/theme.utils';
import React from 'react';
import { IconButton, IconButtonProps, IconVariant } from 'src/legacy/components/blockui';
import { IconStyleProps } from '..';

const WishListButtonC: React.FC<IconButtonProps & IconStyleProps> = (props) => {
    return (
        <Container>
            <StyledIconDesignC {...props} weight={300} />
            <ShadowContainer>
                <CustomIcon size={props.size} variant={IconVariant.outlined} fill weight={300} color="rgba(117, 117, 117, 0.5)">
                    favorite
                </CustomIcon>
            </ShadowContainer>
        </Container>
    );
};

export default WishListButtonC;

const Container = styled.div`
    position: relative;
    display: flex;
`;

const StyledIconDesignC = styled(IconButton)<IconStyleProps>`
    z-index: ${ThemeUtils.zIndex.above};
    padding: 0;
`;

const ShadowContainer = styled.div`
    z-index: ${ThemeUtils.zIndex.base};
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
