import { useDesignComponents } from '@hooks/use-design-components';

import { ProductClient } from '@api/product/product.client';
import { Box } from '@chakra-ui/react';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { useGATracking } from '@hooks/use-ga-tracking';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { useKakaoTracking } from '@hooks/use-kakao-ad-tracker';
import ComponentProduct from '@models/product/component-product';
import WishlistItem from '@models/wishlist-item';
import { Design } from '@type/harmony-config';
import { ProductId } from '@type/models';
import ThemeUtils from '@utils/theme.utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IconButtonProps } from 'src/legacy/components/blockui';
import StyledIconDesignA from './designA/wish-list-button';
import StyledIconDesignB from './designB/wish-list-button';
import WishListButtonC from './designC/wish-list-button';

export type IconStyleProps = {
    /**
     * Show drop shadow
     * @default true
     */
    dropShadow?: boolean;
};

export interface WishListButtonProps extends Omit<IconButtonProps, 'children'>, IconStyleProps {
    product: ComponentProduct | WishlistItem;
    padding?: string;
}

const WishListButton: React.FC<WishListButtonProps> = (props) => {
    const { extensionStatus } = useExtension();

    if (!extensionStatus?.wishlist.isEnabled) {
        return <></>;
    }

    return (
        <Box zIndex={ThemeUtils.zIndex.badge}>
            <WishListButtonInner {...props} />
        </Box>
    );
};

const WishListButtonInner: React.FC<WishListButtonProps> = ({ product, color, padding, ...props }) => {
    const { theme } = useHarmony();
    const router = useRouter();
    const { logEvent } = useGATracking();
    const kakaoTracking = useKakaoTracking();
    const { currencyService } = useGlobalization();
    const Component = useDesignComponents<typeof StyledIconDesignA | typeof StyledIconDesignB>({
        [Design.DesignA]: StyledIconDesignA,
        [Design.DesignB]: StyledIconDesignB,
        [Design.DesignC]: WishListButtonC,
    });
    const defaultIconColor = useDesignComponents<string>({
        [Design.DesignA]: theme.white,
        [Design.DesignB]: theme.primary.default,
    });
    const activatedColor = useDesignComponents<string>({
        [Design.DesignA]: theme.primary.default,
        [Design.DesignB]: theme.primary.default,
        [Design.DesignC]: '#f14b55',
    });
    const { isAuth, redirectToLogin } = useCustomer();

    const [wishListId, setWishListId] = useState<string | number | undefined>(product.wishlistId);

    const updateWishlist = async (productId: ProductId, wishlist: boolean) => {
        try {
            // optimistic update
            setWishListId(wishlist ? 'temp' : undefined);

            if (wishlist) {
                const wishListItem = await ProductClient.addToWishlist(productId);

                logEvent('add_to_wishlist', {
                    currency: currencyService.currencyCode,
                    value: product.minSellPrice,
                    items: [
                        {
                            item_id: productId,
                            item_name: product.title,
                        },
                    ],
                });
                kakaoTracking.addToWishList({ productId });

                setWishListId(wishListItem.id);
            } else if (!wishlist && product.wishlistId) {
                await ProductClient.deleteFromWishlist(Number(wishListId));
                setWishListId(undefined);
            }
        } catch (error) {}
    };

    useEffect(() => {
        setWishListId(product.wishlistId);
    }, [product]);

    useEffect(() => {
        if (isAuth) {
            const wishlistIdToUpdate = WishlistItem.getWishlistId();
            if (wishlistIdToUpdate) {
                updateWishlist(wishlistIdToUpdate, true);
                WishlistItem.deleteWishlistId();
            }
        }
    }, [isAuth]);

    return (
        <Component
            {...props}
            theme={theme}
            fill={!!wishListId}
            color={wishListId ? activatedColor : color || defaultIconColor}
            padding={padding}
            onClick={(e) => {
                e.stopPropagation();
                if (!isAuth) {
                    WishlistItem.setWishlistId(product.productId);
                    return redirectToLogin({ callbackRoute: router.asPath });
                }
                updateWishlist(product.productId, !!!wishListId);
            }}
        >
            favorite
        </Component>
    );
};

export default WishListButton;
