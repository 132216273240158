import { Button, Center, HStack, forwardRef } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import Body2B from '@designs/primitives/typography/body-2-b';
import { useHarmony } from '@hooks/use-harmony';
import { useTranslations } from 'next-intl';
import { MoreButtonProps } from '..';

const MoreButtonA: React.FC<MoreButtonProps> = forwardRef<MoreButtonProps, 'button'>(({ onClick }, ref) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Button onClick={onClick} type="button" variant="unstyled" w="100%" ref={ref}>
            <Center>
                <HStack spacing="2px">
                    <Body2B color={theme.gray[600]}>{t('common.viewMore')}</Body2B>
                    <Icon color={theme.black} size={16}>
                        chevron_right
                    </Icon>
                </HStack>
            </Center>
        </Button>
    );
});

export default MoreButtonA;
