import { Center } from '@chakra-ui/react';
import BadgeA from '@designs/primitives/typography/badge-a';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { ImgCounterProps } from '..';

const ImgCounterD: React.FC<ImgCounterProps> = ({ index, total, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Center position="absolute" top="8px" right="8px" padding="4px 8px" borderRadius="4px" bgColor="rgba(0, 0, 0, 0.50)" zIndex={ThemeUtils.zIndex.badge} {...props}>
            <BadgeA color={theme.white}>
                {index + 1} / {total}
            </BadgeA>
        </Center>
    );
};

export default ImgCounterD;
