import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import SubmenuA from './designA/submenu';
import SubmenuB from './designB/submenu';
import SubmenuC from './designC/submenu';

export type SubmenuStyle = {
    justifyContent?: 'flex-start' | 'space-around' | 'space-between';
    sticky?: boolean;
    /**
     * Sticky top fixed position
     * @example 50px, 0, 2rem
     */
    top?: string | number;
    gap?: number;
    secondary?: boolean;
    padding?: string;
    margin?: string;
    borderBottom?: string;
    minWidth?: string;
    backgroundColor?: string;
};

export interface SubmenuProps extends SubmenuStyle {
    items: string[];
    index?: number;
    onSelect: (index: number) => void;
}

const Submenu: React.FC<SubmenuProps> = (props) => {
    const Component = useDesignComponents<typeof SubmenuA | typeof SubmenuB | typeof SubmenuC>({
        [Design.DesignA]: SubmenuA,
        [Design.DesignB]: SubmenuB,
        [Design.DesignC]: SubmenuC,
    });

    return <Component {...props} />;
};

export default Submenu;
