import { useDesignComponents } from '@hooks/use-design-components';
import ComponentProduct from '@models/product/component-product';
import WishlistItem from '@models/wishlist-item';
import { Design } from '@type/harmony-config';
import WishListButtonPositionA from './designA/wish-list-button-position';
import WishListButtonPositionB from './designB/wish-list-button-position';

export interface WishListButtonPositionProps {
    product: ComponentProduct | WishlistItem;
    size: string;
    disabled?: boolean;
}

const WishListButtonPosition: React.FC<WishListButtonPositionProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof WishListButtonPositionA | typeof WishListButtonPositionB>({
        [Design.DesignA]: WishListButtonPositionA,
        [Design.DesignB]: WishListButtonPositionB,
    });

    return <Component {...props} />;
};

export default WishListButtonPosition;
