import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import BodyText from 'src/legacy/components/common/text/body-text';
import { SubmenuProps, SubmenuStyle } from '../submenu';
import ThemeUtils from '@utils/theme.utils';
import { useDragScroll } from '@hooks/use-mouse-drag';

interface SubmenuStyleProps {
    textColor: string;
    backgroundColor: string;
    borderColor: string;
    borderBottom?: string;
    minWidth?: string;
}

const SubmenuB: React.FC<SubmenuProps> = ({ items, index, onSelect, secondary = false, padding, borderBottom, minWidth, ...props }) => {
    const { theme } = useHarmony();
    const dragScroll = useDragScroll();

    const getSubmenuStyle = (secondary: boolean, active: boolean) => {
        if (secondary && active) return { textColor: theme.gray[900], backgroundColor: theme.white, borderColor: theme.gray[900] };
        if (secondary && !active) return { textColor: theme.gray[800], backgroundColor: theme.gray['50'], borderColor: theme.gray['50'] };
        if (!secondary && active) return { textColor: theme.white, backgroundColor: theme.primary.default, borderColor: theme.primary.default };
        return { textColor: theme.gray[600], backgroundColor: theme.gray['50'], borderColor: theme.gray[200] };
    };

    return (
        <StyledSubmemu theme={theme} padding={padding} borderBottom={borderBottom} {...props} {...dragScroll}>
            {items.map((item, i) => {
                const active = i === index;
                return (
                    <ChipItem key={i} theme={theme} style={getSubmenuStyle(secondary, active)} minWidth={minWidth || 'fit-content'}>
                        <BodyText
                            size="15px"
                            lineHeight="18px"
                            color={getSubmenuStyle(secondary, active)?.textColor}
                            weight={active ? 'bold' : 'regular'}
                            onClick={() => onSelect(i)}
                        >
                            {item}
                        </BodyText>
                    </ChipItem>
                );
            })}
        </StyledSubmemu>
    );
};

export default SubmenuB;

const StyledSubmemu = styled.div<IThemeProps & SubmenuStyle>`
    ${({ justifyContent = 'flex-start', sticky = false, top, theme, gap, padding, borderBottom }) => `
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    gap: ${gap || 10}px;
    flex-wrap: nowrap;
    width: 100%;
    padding: ${padding || '0 16px 8px'};
    overflow-x: auto;
    user-select: none;
    ${
        sticky &&
        `
        position: sticky;
        top: ${top ?? '50px'};
        left: 0;
        z-index: ${ThemeUtils.zIndex.above};
    `
    }
    ${borderBottom && `border-bottom: 1px solid ${theme.gray[50]};`}
  `}
`;

const ChipItem = styled.div<IThemeProps & SubmenuStyle & { style: SubmenuStyleProps; minWidth: string }>`
    ${({ style, minWidth }) => `
        display: inline-block;
        border-radius: 300px;
        padding: 5px 14px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: ${minWidth || 'fit-content'};
        background: ${style.backgroundColor};
        border: solid 1px ${style.borderColor};
    `}
`;
