import styled from '@emotion/styled';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import Link from 'next/link';
import { BlockVStack } from 'src/legacy/components/blockui';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import RoutesEnum from 'src/routes/routes';
import { SwipeListProductProps } from '..';

const SwipeListProductC: React.FC<SwipeListProductProps> = ({ product, ...props }) => {
    const isSoldOut = product instanceof ComponentProduct ? product.isSoldOut : false;
    // TODO : (Allie) add isFreeShipping condition
    const hasProductBadges = product.timesaleAppliedNow || isSoldOut;

    return (
        <Link
            href={{
                pathname: RoutesEnum.ProductDetail,
                query: { productId: product.id },
            }}
        >
            <Container {...props}>
                <BlockVStack spacing="8px">
                    <ImageContainer>
                        {product.image && <ProductCardImage radius={4} disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                        <WishlistContainer>
                            <WishListButton size="24px" product={product} />
                        </WishlistContainer>
                    </ImageContainer>
                    <ProductCardDetails product={product} lines={2} />
                    {/* TODO : (Allie) need to fix isFreeShipping later if it is needed */}
                    {hasProductBadges && <ProductBadges direction={Direction.Horizontal} isSoldOut={isSoldOut} isSpecial={product.timesaleAppliedNow} isFreeShipping={false} />}
                    <TextBadge padding="0px" />
                </BlockVStack>
            </Container>
        </Link>
    );
};

export default SwipeListProductC;

const Container = styled.div`
    width: 140px;
    margin-right: 8px;
    line-height: 1;
    :nth-last-of-type(1) {
        margin-right: 0px;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    width: 140px;
    height: 140px;
    overflow: hidden;
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 6px;
`;
