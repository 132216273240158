import WishListButton from '@legacy/designs/wish-list-button';
import styled from '@emotion/styled';

const WishListButtonPositionA = styled(WishListButton)`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
`;

export default WishListButtonPositionA;
