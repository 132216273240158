import { Button, Center, VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareLinkButtonInnerProps } from '..';

const ShareLinkButtonA: React.FC<ShareLinkButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center bg={theme.gray[50]} borderRadius="12px" w="88px" h="88px">
                    <Icon size={48}>Link</Icon>
                </Center>
                <BodyText color={theme.gray[800]} size="17px">
                    Copy URL
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareLinkButtonA;
