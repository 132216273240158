import { Button, Center, VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareLinkButtonInnerProps } from '..';

const ShareLinkButtonC: React.FC<ShareLinkButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center border="1px solid" borderColor={theme.gray[100]} borderRadius="16px" w="72px" h="72px">
                    <Icon size={40}>Link</Icon>
                </Center>
                <BodyText size="16px" lineHeight="20px" color={theme.gray[800]}>
                    Copy URL
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareLinkButtonC;
