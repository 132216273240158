import { Box } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import { CounterBarProps } from '..';

const CounterBarA: React.FC<CounterBarProps> = ({ currentCount, maxCount }) => {
    const { theme } = useHarmony();

    return (
        <Box h="2px" my="8px" mx="16px" bgColor={theme.gray[50]}>
            <Box w={`${(currentCount / maxCount) * 100}%`} h="100%" bgColor={theme.gray[900]} transition="0.1s ease-in-out" />
        </Box>
    );
};

export default CounterBarA;
