import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import ImgCounterD from './designD/img-counter';
import ImgCounterC from './designC/img-counter';

export interface ImgCounterProps extends BoxProps {
    index: number;
    total: number;
}

const ImgCounter: React.FC<ImgCounterProps> = (props) => {
    const Component = useDesignComponents<ComponentType<ImgCounterProps>>({
        [Design.DesignC]: ImgCounterC,
        [Design.DesignD]: ImgCounterD,
    });

    return <Component {...props} />;
};

export default ImgCounter;
