import styled from '@emotion/styled';
import { FixedProductListContainerProps } from '..';

const FixedProductListContainerB = styled.div<FixedProductListContainerProps>`
    display: grid;
    gap: 16px 16px;
    place-content: center center;
    width: 100%;
    padding: 8px 16px 0 16px;
    overflow: hidden;

    ${({ cols }) => `
      grid-template-columns: repeat(${cols}, 1fr);
    `}
`;

export default FixedProductListContainerB;
