import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useDragScroll } from '@hooks/use-mouse-drag';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import BodyText from 'src/legacy/components/common/text/body-text';
import { SubmenuProps, SubmenuStyle } from '../submenu';

const SubmenuA: React.FC<SubmenuProps> = ({ items, index, onSelect, margin, padding, ...props }) => {
    const { theme } = useHarmony();
    const dragScroll = useDragScroll();

    return (
        <StyledSubmemu theme={theme} margin={margin} padding={padding} {...props} {...dragScroll}>
            {items.map((item, i) => {
                const active = i === index;
                return (
                    <SubmenuButton
                        key={i}
                        theme={theme}
                        staticSize="14px"
                        color={active ? theme.black : theme.gray[600]}
                        weight={active ? 'bold' : 'thin'}
                        className={active ? ' active' : ''}
                        onClick={() => onSelect(i)}
                    >
                        {item}
                    </SubmenuButton>
                );
            })}
        </StyledSubmemu>
    );
};

export default SubmenuA;

const StyledSubmemu = styled.div<IThemeProps & SubmenuStyle>`
    ${({ justifyContent = 'flex-start', sticky = false, top, margin = '0 0 6px', padding = ' 0px 16px 3px 16px;' }) => `
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    flex-wrap: nowrap;
    width: 100%;
    padding: ${padding};
    margin: ${margin};
    overflow-x: auto;
    user-select: none;
    background: white;
    ${
        sticky &&
        `
        position: sticky;
        top: ${top ?? '50px'};
        left: 0;
        z-index: ${ThemeUtils.zIndex.above};
    `
    }
  `}
`;

const SubmenuButton = styled(BodyText)<IThemeProps>`
    ${({ theme }) => `
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 45px;
        margin-right: 12px;
        border-bottom: 4px solid transparent;
        cursor: pointer;
        &.active {
            border-bottom: 4px solid ${theme.secondary.default};
        }
    `}
`;
