import { useDesignComponents } from '@hooks/use-design-components';

import CustomLink from '@components/custom-link';
import { Design } from '@type/harmony-config';
import { LinkProps } from 'next/link';
import { ComponentType } from 'react';
import MoreButtonD from './designD/more-button';
import MoreButtonA from './designA/more-button';

export interface MoreButtonProps {
    onClick?: () => void;
    pagePath?: LinkProps['href'];
}

const MoreButton: React.FC<MoreButtonProps> = ({ pagePath, ...props }) => {
    const Component = useDesignComponents<ComponentType<MoreButtonProps>>({
        [Design.DesignA]: MoreButtonA,
        [Design.DesignD]: MoreButtonD,
    });

    return (
        <CustomLink href={pagePath}>
            <Component {...props} />
        </CustomLink>
    );
};

export default MoreButton;
