import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import Link from 'next/link';
import ProductBadges from 'src/legacy/components/product/product-badges';
import RoutesEnum from 'src/routes/routes';
import { SwipeListProductProps } from '..';
import { Box } from '@chakra-ui/react';

const SwipeListProductDesignA: React.FC<SwipeListProductProps> = ({ product, ...props }) => {
    const { theme } = useHarmony();

    return (
        <ImageListProduct theme={theme} {...props}>
            <Link
                href={{
                    pathname: RoutesEnum.ProductDetail,
                    query: { productId: product.id },
                }}
            >
                <div>
                    <div className="image">
                        {product.image && <ProductCardImage disabled={product.isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                        <WishlistContainer>
                            <WishListButton className="heart" size="20px" product={product} />
                        </WishlistContainer>
                    </div>
                    <Box mt="8px">
                        <ProductCardDetails product={product} />
                    </Box>
                    <PaddedContainer padding="6px 0 0 0">
                        <TextBadge />
                    </PaddedContainer>
                </div>
            </Link>
            <AbsoluteContainer>
                <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
            </AbsoluteContainer>
        </ImageListProduct>
    );
};

export default SwipeListProductDesignA;

const ImageListProduct = styled('div')<IThemeProps>`
    ${({ theme }) => `
    width: 120px;
    margin-right: 6px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    &:nth-last-of-type(1) {
        margin-right: 0px;
    }
    .image {
        position: relative;
        width: 120px;
        height: 120px;
        font-family: ${theme.font.default.light};
        border: 1px solid ${theme.gray['50']}
    }
    .name {
      margin-top: 8px;
      font-family: ${theme.font.default.regular};
      font-size: 0.75rem;
      letter-spacing: -0.04em;
      color: ${theme.gray[600]};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .bold {
        width: 100%;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .price {
      margin-top: 6px;
      line-height: 1;
      .list-price {
        position: relative;
        display: inline-block;
        color: ${theme.gray[50]};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.75rem;
        letter-spacing: -0.03em;
        &:after {
          content: "";
          position: absolute;
          left: 0px;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: ${theme.gray[50]};
        }
      }
      .discount-price {
        color: ${theme.gray[900]};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.9375rem;
        letter-spacing: -0.03em;
      }
      .discount-percent {
        margin-right: 2px;
        color: ${theme.primary.default};
        font-family: ${theme.font.secondary.regular};
        font-size: 0.9375rem;
        letter-spacing: -0.03em;
      }
      .unit {
        color: ${theme.gray[900]};
        font-family: ${theme.font.default.regular};
        font-size: 0.6875rem;
        letter-spacing: -0.03em;
      }
    }
    `}
`;

const WishlistContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;
`;
