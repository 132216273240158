import { useDesignComponents } from '@hooks/use-design-components';

import { ProductClient } from '@api/product/product.client';
import { useExtension } from '@hooks/use-extension';
import { useGATracking } from '@hooks/use-ga-tracking';
import { useGlobalization } from '@hooks/use-globalization';
import { useKakaoTracking } from '@hooks/use-kakao-ad-tracker';
import { useWishlistItems } from '@hooks/use-wishlist';
import { Design } from '@type/harmony-config';
import { ComponentType, useEffect, useState } from 'react';
import WishlistButtonD from './designD/wishlist-button';

interface WishlistButtonProps {
    productId: string;
    productName: string;
    productPrice: number;
    wishlistId: number | undefined;
}

export interface WishlistButtonInnerProps {
    onClick: () => void;
    optimisticLiked: boolean;
}

const WishlistButton: React.FC<WishlistButtonProps> = ({ productId, wishlistId, productName, productPrice }) => {
    const { extensionStatus } = useExtension();
    const { currencyService } = useGlobalization();
    const { logEvent } = useGATracking();
    const kakaoTracking = useKakaoTracking();
    const Component = useDesignComponents<ComponentType<WishlistButtonInnerProps>>({
        [Design.DesignD]: WishlistButtonD,
    });

    const { wishlistItems, addToWishlistItems, removeFromWishlistItems } = useWishlistItems();

    const [optimisticLiked, setOptimisticLiked] = useState<boolean>(false);
    const [tempWishlistId, setTempWishlistId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (wishlistId) {
            setTempWishlistId(wishlistId);
            return;
        }
        setTempWishlistId(undefined);
    }, [wishlistId]);

    useEffect(() => {
        setOptimisticLiked(wishlistItems.has(productId) || !!tempWishlistId);
    }, [wishlistItems, productId, wishlistId, tempWishlistId]);

    if (!extensionStatus?.wishlist.isEnabled) {
        return <></>;
    }

    const onClick = async () => {
        if (optimisticLiked && tempWishlistId) {
            await ProductClient.deleteFromWishlist(tempWishlistId);
            removeFromWishlistItems(productId);
            setOptimisticLiked(false);
            setTempWishlistId(undefined);
            return;
        }
        addToWishlistItems(productId);
        setOptimisticLiked(true);
        const wishlist = await ProductClient.addToWishlist(productId);

        logEvent('add_to_wishlist', {
            currency: currencyService.currencyCode,
            value: productPrice,
            items: [
                {
                    item_id: productId,
                    item_name: productName,
                },
            ],
        });
        kakaoTracking.addToWishList({ productId });

        setTempWishlistId(wishlist.id);
    };

    return <Component onClick={onClick} optimisticLiked={optimisticLiked} />;
};

export default WishlistButton;
