import { Center } from '@chakra-ui/react';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { ImgCounterProps } from '..';
import BodyText from '@legacy/components/common/text/body-text';

const ImgCounterC: React.FC<ImgCounterProps> = ({ index, total, ...props }) => {
    const { theme } = useHarmony();

    return (
        <Center position="absolute" top="12px" right="16px" padding="6px 12px" borderRadius="100px" bgColor="rgba(0, 0, 0, 0.6)" zIndex={ThemeUtils.zIndex.badge} {...props}>
            <BodyText key={index} size="14px" color={theme.white}>
                {index + 1} / {total}
            </BodyText>
        </Center>
    );
};

export default ImgCounterC;
