import { useHarmony } from '@hooks/use-harmony';
import { PaginationButtonProps } from '..';
import IconButton from '@designs/primitives/common/icon-button';

const PaginationButtonA: React.FC<PaginationButtonProps> = ({ isDisabled, children, ...props }) => {
    const { theme } = useHarmony();

    return (
        <IconButton
            variant={'solid-gray'}
            w={'24px'}
            maxW={'24px'}
            h={'24px'}
            color={isDisabled ? theme.icon.gray400 : theme.gray[800]}
            display={'inline-flex'}
            justifyContent={'center'}
            isDisabled={isDisabled}
            {...props}
        >
            {children}
        </IconButton>
    );
};

export default PaginationButtonA;
