import { useDisclosure } from '@chakra-ui/react';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import ComponentProduct from '@models/product/component-product';
import { IconButtonProps } from 'src/legacy/components/blockui';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import ShareDrawer from './share-drawer';

interface ShareButtonProps extends Partial<Omit<IconButtonProps, 'children'>> {
    product: ComponentProduct;
}

const ShareButton: React.FC<ShareButtonProps> = (props) => {
    const { extensionStatus } = useExtension();

    if (!extensionStatus?.shareWith.isEnabled) {
        return <></>;
    }

    return <ShareButtonInner {...props} />;
};

const ShareButtonInner: React.FC<ShareButtonProps> = ({ product, ...props }) => {
    const { theme } = useHarmony();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <CustomIconButton color={theme.gray[300]} size="25px" onClick={onOpen} {...props}>
                share
            </CustomIconButton>
            <ShareDrawer open={isOpen} onClose={onClose} product={product} />
        </>
    );
};

export default ShareButton;
