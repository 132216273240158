import { useDesignComponents } from '@hooks/use-design-components';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import ButtonA from './designA/chevron-button';
import ButtonB from './designB/chevron-button';
import ButtonC from './designC/chevron-button';

export enum ArrowDirectionEnum {
    Up,
    Down,
    Right,
}

export type ButtonStyleProps = {
    color?: string;
};

export interface ChevronButtonProps extends Omit<React.HTMLProps<HTMLDivElement>, 'as'>, ButtonStyleProps {
    children: ReactNode;
    arrowDirection: ArrowDirectionEnum;
}

const ChevronButton: React.FC<ChevronButtonProps> = ({ children, arrowDirection, color, ...props }) => {
    const { theme, designSettings } = useHarmony();

    const Component = useDesignComponents<typeof ButtonA | typeof ButtonB>({
        [Design.DesignA]: ButtonA,
        [Design.DesignB]: ButtonB,
        [Design.DesignC]: ButtonC,
    });
    const baseColor = useDesignComponents<string | undefined>({
        [Design.DesignA]: undefined,
        [Design.DesignB]: color || theme.gray[400],
        [Design.DesignC]: theme.gray[400],
    });

    return (
        <Component role="button" theme={theme} color={baseColor} fontSize={designSettings?.getLegacyFontSize('16px')} arrowDirection={arrowDirection} {...props}>
            {children}
            {arrowDirection === ArrowDirectionEnum.Up && <CustomIcon color={baseColor}>expand_less</CustomIcon>}
            {arrowDirection === ArrowDirectionEnum.Down && <CustomIcon color={baseColor}>expand_more</CustomIcon>}
            {arrowDirection === ArrowDirectionEnum.Right && <CustomIcon color={baseColor}>chevron_right</CustomIcon>}
        </Component>
    );
};

export default ChevronButton;
