import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import HideOnEmptyBox from '@components/hide-on-empty-box';
import WishlistButton from '@designs/grouped/wishlist/wishlist-button';
import Badge, { BadgeType } from '@designs/primitives/common/badge/product-badge';
import ProductCardImage from '@designs/primitives/common/product-card/product-card-image';
import Body2B from '@designs/primitives/typography/body-2-b';
import Body2C from '@designs/primitives/typography/body-2-c';
import DetailA from '@designs/primitives/typography/detail-a';
import StrikeThrough from '@designs/primitives/typography/strike-through';
import { useGlobalization } from '@hooks/use-globalization';
import { useHarmony } from '@hooks/use-harmony';
import { useSellPricePolicy } from '@hooks/use-sell-price-policy';
import { useTranslations } from 'next-intl';
import { AgeVerificationIcon } from 'src/designs/grouped/common/age-verification-icon';
import { FixedProductCardProps } from '..';

const FixedProductCardD: React.FC<FixedProductCardProps> = ({ product, handleClick, isDisabled, isEllipsisBrandName }) => {
    const { theme, designSettings } = useHarmony();
    const t = useTranslations();
    const { currencyService } = useGlobalization();
    const { getPriceWithUnitPolicy } = useSellPricePolicy();

    return (
        <Box bgColor={theme.background.white} onClick={handleClick}>
            <Box pos="relative">
                {product.image && <ProductCardImage imageUrl={product.image} alt={product.title} w="100%" isDisabled={isDisabled} />}
                <Box pos="absolute" bottom={0} right={0}>
                    <WishlistButton productId={product.productId} productName={product.title} productPrice={product.minSellPrice} wishlistId={product.wishlistId} />
                </Box>
                {product.isAgeVerificationRequired && (
                    <Box pos="absolute" bottom={0} left={0} p="4px">
                        <AgeVerificationIcon />
                    </Box>
                )}
            </Box>
            {/* Price */}
            <VStack spacing="2px" mt="8px">
                {product.hasDiscount && designSettings?.isShowMarketPrice && <StrikeThrough color={theme.text.disabled}>{product.displayedMarketPriceString}</StrikeThrough>}
                <HStack spacing="2px">
                    {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinSubPrice && (
                        <Body2C color={theme.text.title1} flexShrink="0">
                            {t('common.pluralMonth', { month: product.subscriptionMonthlyUnit })}/
                        </Body2C>
                    )}
                    <Flex flexShrink="0">
                        <Body2C color={theme.text.title1}>{getPriceWithUnitPolicy(product.displayedSellPrice)}</Body2C>
                        <Body2B color={theme.text.title1}>
                            {currencyService.currencySymbol}
                            {product.isSubscriptionSalesType && product.subscriptionInfo?.priceDisplayTypeIsMinPurPrice && '~'}
                        </Body2B>
                    </Flex>
                    {product.hasDiscount && designSettings?.isShowDiscountRate && <Body2C color={theme.text.secondary}>{product.discountRate}%</Body2C>}{' '}
                </HStack>
            </VStack>

            {/* Product brand and title */}
            <Center mt="4px">
                <Body2C noOfLines={2} wordBreak="break-all" textAlign="center">
                    {product.showBrandOnProductCard && (
                        <Flex display="inline-flex" whiteSpace="pre-wrap" wordBreak="break-all" noOfLines={isEllipsisBrandName ? 1 : undefined}>
                            {product.brand}{' '}
                        </Flex>
                    )}
                    <Body2B as="span">{product.title}</Body2B>
                </Body2C>
            </Center>

            {/* Badges */}
            <HideOnEmptyBox display="flex" justifyContent="center" columnGap="2px" mt="12px">
                {product.timesaleAppliedNow && <Badge badgeType={BadgeType.Special}>{t('common.special')}</Badge>}
                {product.isSoldOut && <Badge badgeType={BadgeType.SoldOut}>{t('product.soldOut')}</Badge>}
            </HideOnEmptyBox>

            {/* Text badge */}
            {designSettings?.isTextBadgeOn && (
                <Center mt="4px">
                    <DetailA color={theme.text.body2}>{designSettings?.textBadgeContent}</DetailA>
                </Center>
            )}
        </Box>
    );
};

export default FixedProductCardD;
