import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import CounterBarA from './designA/counter-bar';
import CounterBarB from './designB/counter-bar';
import CounterBarD from './designD/counter-bar';

export interface CounterBarProps {
    /** Starts from 1. */
    currentCount: number;
    maxCount: number;
}

const CounterBar: React.FC<CounterBarProps> = (props) => {
    const Component = useDesignComponents<ComponentType<CounterBarProps>>({
        [Design.DesignA]: CounterBarA,
        [Design.DesignB]: CounterBarB,
        [Design.DesignD]: CounterBarD,
    });

    return <Component {...props} />;
};

export default CounterBar;
