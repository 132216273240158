import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import SoldOutBadge from '@legacy/designs/sold-out-badge';
import TextBadge from '@legacy/designs/text-badge';
import WishListButtonPosition from '@legacy/designs/wish-list-button-position';
import ComponentProduct from '@models/product/component-product';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import ProductBadges from 'src/legacy/components/product/product-badges';
import { InternalFixedListProductProps } from '../fixed-list-product';

const FixedListProductA: React.FC<InternalFixedListProductProps> = ({ product, rank, cols, disabled, onClick, isDiscounted, index }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const { extensionStatus } = useExtension();
    const isSoldOut = product instanceof ComponentProduct ? product.isSoldOut : false;

    return (
        <RelativeContainer>
            <ProductListProductStyled role="button" maxScreenWidth={extensionStatus?.maxScreenWidth} cols={cols} theme={theme} onClick={onClick} className={`product-${index}`}>
                <div className="image">
                    {product.image && <ProductCardImage disabled={disabled || isSoldOut} src={product.image} alt={product.title} layout={'fill'} objectFit="cover" />}
                    {rank && <div className="rank">{rank}</div>}
                    <WishListButtonPosition size="24px" product={product} />
                </div>
                <Box mt="8px">
                    <ProductCardDetails product={product} isDiscounted={isDiscounted} cols={cols} isEllipsisBrandName={cols > 1} />
                </Box>
                <PaddedContainer padding="6px 0 0 0">
                    <TextBadge />
                </PaddedContainer>
            </ProductListProductStyled>
            <AbsoluteContainer>
                <ProductBadges isSoldOut={isSoldOut} isSpecial={product.timesaleAppliedNow} />
            </AbsoluteContainer>
        </RelativeContainer>
    );
};

export default FixedListProductA;

export const ProductListProductStyled = styled('div')<
    {
        cols: number;
    } & IThemeProps &
        ScreenSizeProps
>`
    ${({ cols, theme, maxScreenWidth }) => `
  width: 100%;
  min-width: 100%;
  line-height: 1;
  position: relative;
  &:nth-last-of-type(1) {
    margin-right: 0px;
  }
  .image {
    position: relative;
    width: 100%;
    height: calc((min(100vw, ${maxScreenWidth}px) - ${(cols - 1) * 8}px - 32px) / ${cols});
    border: 1px solid ${theme.gray[50]};
    .rank {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-weight: semi-bol;
      font-size: 0.875rem;
      line-height: 1;
      color: ${theme.black};
      background-color: ${'yellow'};
    }
  }
  `}
`;

const RelativeContainer = styled.div`
    width: 100%;
    min-width: 100%;
    position: relative;
`;

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;
`;

const AbsoluteSoldOut = styled(SoldOutBadge)`
    position: absolute;
    top: 8px;
    left: 8px;
`;
