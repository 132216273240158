import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';

const StyledChevronButton = styled.div<IThemeProps>`
    ${({ theme, fontSize }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        border: 1px solid ${theme.gray[300]};
        font-family: ${theme.font.default.regular};
        font-size: ${fontSize};
        letter-spacing: -0.04em;
        color: ${theme.gray[800]};
        line-height: 1;
        cursor: pointer;
    `}
`;

export default StyledChevronButton;
