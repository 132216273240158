import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalProductBadgesProps } from '..';

const SoldOutBadgeA = ({ children, ...props }: InternalProductBadgesProps) => {
    return (
        <Box backgroundColor="black" p="3px 8px 5px">
            <StyledBodyText size="16px" color="white" lineHeight="14px" weight="medium" {...props}>
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SoldOutBadgeA;

const StyledBodyText = styled(BodyText)`
    display: block;
`;
