import styled from '@emotion/styled';
import { IconStyleProps } from '..';
import { IconButton } from 'src/legacy/components/blockui';

const StyledIconDesignA = styled(IconButton)<IconStyleProps>`
    ${({ dropShadow = true }) => `
        filter: ${dropShadow ? 'drop-shadow(0px 1px 1px rgb(0 0 0 / 0.6))' : 'none'};
    `}
`;

export default StyledIconDesignA;
