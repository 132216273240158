import { useDesignComponents } from '@hooks/use-design-components';
import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import { ProductId } from '@type/models';
import { useRouter } from 'next/router';
import { ComponentType, useMemo } from 'react';
import RoutesEnum from 'src/routes/routes';
import FixedListProductA from './designA/fixed-list-product';
import FixedListProductB from './designB/fixed-list-product';
import FixedListProductC from './designC/fixed-list-product';

export type ProductStyleProps = {
    disabled?: boolean;
};

export interface FixedListProductProps extends ProductStyleProps {
    product: ComponentProduct;
    rank?: number;
    /**@default 2 */
    cols?: number;
    isTimeSale?: boolean;
    onClick?: (productId: ProductId) => void;
    index?: number;
}

export interface InternalFixedListProductProps extends FixedListProductProps {
    cols: number;
    isDiscounted: boolean;
    onClick: () => void;
}

const FixedListProduct: React.FC<FixedListProductProps> = ({ onClick, ...props }) => {
    const Component = useDesignComponents<ComponentType<InternalFixedListProductProps>>({
        [Design.DesignA]: FixedListProductA,
        [Design.DesignB]: FixedListProductB,
        [Design.DesignC]: FixedListProductC,
    });
    const router = useRouter();

    const isDiscounted = useMemo<boolean>(() => {
        if (props.isTimeSale && props.product instanceof ComponentProduct && props.product.timesaleApplied) return true;
        return props.product.timesaleAppliedNow;
    }, [props.isTimeSale, props.product]);

    return (
        <Component
            isDiscounted={isDiscounted}
            onClick={() => {
                if (!props.disabled) {
                    if (!!onClick) {
                        onClick(props.product.productId);
                    } else {
                        router.push({
                            pathname: RoutesEnum.ProductDetail,
                            query: { productId: props.product.productId },
                        });
                    }
                }
            }}
            cols={props.cols || 2}
            {...props}
        />
    );
};

export default FixedListProduct;
