import CustomChip from 'src/legacy/components/common/custom-chip';
import BodyText from 'src/legacy/components/common/text/body-text';
import { useHarmony } from '@hooks/use-harmony';
import { TextBadgeProps } from '..';

const TextBadgeA: React.FC<TextBadgeProps> = ({ large, textBadgeContent }) => {
    const { theme } = useHarmony();
    return (
        <>
            {large ? (
                <BodyText staticSize="13px" lineHeight="20px" color={theme.gray[800]}>
                    {textBadgeContent}
                </BodyText>
            ) : (
                <CustomChip backgroundColor={theme.gray['50']}>
                    <BodyText staticSize="13px">{textBadgeContent}</BodyText>
                </CustomChip>
            )}
        </>
    );
};

export default TextBadgeA;
