import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import ProductCardDetails from '@legacy/designs/product-card-details';
import TextBadge from '@legacy/designs/text-badge';
import WishListButton from '@legacy/designs/wish-list-button';
import ComponentProduct from '@models/product/component-product';
import { ScreenSizeProps } from '@type/common';
import React from 'react';
import ProductBadges, { Direction } from 'src/legacy/components/product/product-badges';
import ProductCardImage from '../../product-card-image';
import { InternalFixedListProductProps } from '../fixed-list-product';

const FixedListProductC: React.FC<InternalFixedListProductProps> = ({ product, cols, disabled, onClick, isDiscounted }) => {
    const isComponentProduct = product instanceof ComponentProduct;
    const isSoldOut = product instanceof ComponentProduct ? product.isSoldOut : false;
    const { extensionStatus } = useExtension();

    return (
        <Container onClick={onClick}>
            <ImageContainer cols={cols} maxScreenWidth={extensionStatus?.maxScreenWidth}>
                {product.image && (
                    <ProductCardImage
                        radius={4}
                        disabled={disabled || (isComponentProduct && product.isSoldOut)}
                        src={product.image}
                        alt={product.title}
                        layout={'fill'}
                        objectFit="cover"
                    />
                )}
                <WishlistContainer>
                    <WishListButton size="24px" product={product} />
                </WishlistContainer>
            </ImageContainer>
            <ProductCardDetails product={product} isDiscounted={isDiscounted} cols={cols} isEllipsisBrandName={cols > 1} />
            {/* TODO : (Allie) need to fix isFreeShipping later if it is needed */}
            <ProductBadges direction={Direction.Horizontal} isSoldOut={isSoldOut} isSpecial={product.timesaleAppliedNow} isFreeShipping={false} />
            <TextBadge padding="0px" />
        </Container>
    );
};

export default FixedListProductC;

const Container = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    :nth-last-of-type(1) {
        padding-bottom: 12px;
    }
`;

const ImageContainer = styled.div<{ cols: number } & ScreenSizeProps>`
    ${({ cols, maxScreenWidth }) => `
    position: relative;
    width: 100%;
    height: calc((min(100vw, ${maxScreenWidth}px) - ${(cols - 1) * 8}px - 32px) / ${cols});
    overflow: hidden;
  `}
`;

const WishlistContainer = styled.div`
    position: absolute;
    bottom: 6px;
    right: 6px;
`;
