import { Button, VStack } from '@chakra-ui/react';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import CustomImage from '@legacy/components/common/custom-image';
import { ShareKakaoButtonInnerProps } from '..';

const ShareKakaoButtonD: React.FC<ShareKakaoButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled" shadow={theme.dropShadow.shadow3} p="8px 4px" minW="120px" borderRadius="4px">
            <VStack spacing="8px" w="100%">
                <CustomImage src={'/image/icon_kakao.png'} alt="kakao" width={40} height={40} layout="fixed" objectFit="contain" />
                <Body2C color={theme.text.body1}>Kakaotalk</Body2C>
            </VStack>
        </Button>
    );
};

export default ShareKakaoButtonD;
