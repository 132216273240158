import { Box } from '@chakra-ui/react';
import { AgeVerificationIcon } from '@designs/grouped/common/age-verification-icon';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import ProductCardDetails from '@legacy/designs/product-card-details';
import ProductCardImage from '@legacy/designs/product-card-image';
import SoldOutBadge from '@legacy/designs/sold-out-badge';
import WishListButtonPosition from '@legacy/designs/wish-list-button-position';
import ComponentProduct from '@models/product/component-product';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import { useTranslations } from 'next-intl';
import { InternalFixedListProductProps } from '../fixed-list-product';

const FixedListProductB: React.FC<InternalFixedListProductProps> = ({ product, cols, disabled, onClick, isDiscounted }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const { extensionStatus } = useExtension();
    const isComponentProduct = product instanceof ComponentProduct;
    return (
        <RelativeContainer>
            <StyledProductCard role="button" onClick={onClick}>
                <ImageContainer theme={theme} cols={cols} maxScreenWidth={extensionStatus?.maxScreenWidth}>
                    {product.image && (
                        <ProductCardImage
                            disabled={disabled || (isComponentProduct && product.isSoldOut)}
                            src={product.image}
                            alt={product.title}
                            layout={'fill'}
                            objectFit="cover"
                        />
                    )}
                    {product.isAgeVerificationRequired && (
                        <Box pos="absolute" top="12px" right="12px">
                            <AgeVerificationIcon />
                        </Box>
                    )}
                    <WishListButtonPosition size="20px" product={product} disabled={disabled} />
                </ImageContainer>
                <ProductCardDetails cols={cols} product={product} isDiscounted={isDiscounted} lines={2} isEllipsisBrandName={cols > 1} />
            </StyledProductCard>
        </RelativeContainer>
    );
};

export default FixedListProductB;

export const StyledProductCard = styled.div`
    width: 100%;
    min-width: 100%;
    line-height: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ImageContainer = styled.div<IThemeProps & ScreenSizeProps & { cols: number }>`
    ${({ theme, cols, maxScreenWidth }) => `
    position: relative;
    width: 100%;
    height: calc((min(100vw, ${maxScreenWidth}px) - ${(cols - 1) * 8}px - 32px) / ${cols});
    border: 1px solid ${theme.gray[200]};
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.12);
  `}
`;

const RelativeContainer = styled.div`
    width: 100%;
    min-width: 100%;
    position: relative;
`;

const AbsoluteSoldOut = styled(SoldOutBadge)`
    position: absolute;
    bottom: 8px;
    left: 8px;
`;
