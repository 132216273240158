import { useDesignComponents } from '@hooks/use-design-components';

import PaginationButtonD from './designD/pagination-button';
import { Design } from '@type/harmony-config';
import { ComponentType } from 'react';
import { IconButtonProps } from '../../icon-button';
import PaginationButtonA from './designA/pagination-button';

export interface PaginationButtonProps extends IconButtonProps {
    isDisabled?: boolean;
}

const PaginationButton: React.FC<PaginationButtonProps> = (props) => {
    const Component = useDesignComponents<ComponentType<PaginationButtonProps>>({
        [Design.DesignA]: PaginationButtonA,
        [Design.DesignB]: PaginationButtonA,
        [Design.DesignC]: PaginationButtonA,
        [Design.DesignD]: PaginationButtonD,
    });

    return <Component {...props} />;
};

export default PaginationButton;
