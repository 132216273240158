import { Button, Center, VStack } from '@chakra-ui/react';
import Icon from '@designs/primitives/common/icon';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { ShareLinkButtonInnerProps } from '..';

const ShareLinkButtonB: React.FC<ShareLinkButtonInnerProps> = ({ onClick }) => {
    const { theme } = useHarmony();

    return (
        <Button onClick={onClick} variant="unstyled">
            <VStack spacing="8px">
                <Center boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.12)" borderRadius="16px" w="72px" h="72px">
                    <Icon size={40}>Link</Icon>
                </Center>
                <BodyText size="18px" lineHeight="22px">
                    Copy URL
                </BodyText>
            </VStack>
        </Button>
    );
};

export default ShareLinkButtonB;
