import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import BodyText from '@legacy/components/common/text/body-text';
import { InternalSpecialPriceBadgeProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const SpecialPriceBadgeA = ({ theme, children, ...props }: InternalSpecialPriceBadgeProps) => {
    return (
        <Box backgroundColor={theme.secondary.default} p="4px 8px" zIndex={ThemeUtils.zIndex.badge}>
            <StyledBodyText size="16px" color="black" lineHeight="14px" weight="medium" {...props}>
                {children}
            </StyledBodyText>
        </Box>
    );
};

export default SpecialPriceBadgeA;

const StyledBodyText = styled(BodyText)`
    display: block;
`;
