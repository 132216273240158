import { Grid, VStack } from '@chakra-ui/react';
import FixedProductCard from '@designs/grouped/common/product-card/fixed-product-card';
import DesignedContainer from '@designs/primitives/common/designed-container';
import MoreButton from '@designs/primitives/main/main-section-title/more-button';
import { useGATracking } from '@hooks/use-ga-tracking';
import { FixedProductCardSectionProps } from '..';

const FixedProductCardSectionD: React.FC<FixedProductCardSectionProps> = ({ type = 'M', products, onProductClick, trackItemSelect, viewMoreHref, isDisabled }) => {
    const cols = { L: 1, M: 2 }[type];
    const spacing = { L: '12px', M: '24px' }[type];
    const { logEvent } = useGATracking();

    return (
        <DesignedContainer>
            <VStack spacing={spacing} pb="16px" align="stretch" w="100%">
                <Grid templateColumns={`repeat(${cols}, 1fr)`} p="16px 16px 0px" gap="24px 8px">
                    {products.map((product) => (
                        <FixedProductCard
                            key={product.id}
                            product={product}
                            onClick={onProductClick}
                            isDisabled={isDisabled}
                            trackItemSelect={trackItemSelect}
                            isEllipsisBrandName={cols > 1}
                        />
                    ))}
                </Grid>
                {!!viewMoreHref && <MoreButton pagePath={viewMoreHref} />}
            </VStack>
        </DesignedContainer>
    );
};

export default FixedProductCardSectionD;
