import { MouseEvent, useRef } from 'react';

export const useDragScroll = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        isDown.current = true;
        ref.current.style.cursor = 'grabbing';
        startX.current = e.pageX - ref.current.offsetLeft;
        scrollLeft.current = ref.current.scrollLeft;
    };

    const onMouseLeave = () => {
        if (!ref.current) {
            return;
        }

        isDown.current = false;
        ref.current.style.cursor = 'grab';
    };

    const onMouseUp = () => {
        if (!ref.current) {
            return;
        }

        isDown.current = false;
        ref.current.style.cursor = 'grab';
    };

    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        if (!isDown.current) return;
        e.preventDefault();
        const x = e.pageX - ref.current.offsetLeft;
        const walk = (x - startX.current) * 2; // 드래그 속도 조절
        ref.current.scrollLeft = scrollLeft.current - walk;
    };

    return {
        ref,
        onMouseDown,
        onMouseLeave,
        onMouseUp,
        onMouseMove,
    };
};
