import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { ButtonStyleProps, ChevronButtonProps } from '..';

const StyledChevronButton = styled.div<IThemeProps & ButtonStyleProps & ChevronButtonProps>`
    ${({ theme, color, fontSize, arrowDirection }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: ${arrowDirection ? '16px' : 'none'};
    width: 100%;
    border-radius: 12px;
    border: 1px solid ${color || theme.gray[300]};
    font-family: ${theme.font.default.semiBold};
    font-size: ${fontSize};
    color: ${color || theme.gray[700]};
    line-height: 1;
    cursor: pointer;
    background-color: white;
    `}
`;

export default StyledChevronButton;
