import { useDesignComponents } from '@hooks/use-design-components';

import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import ProductPriceA from './designA/product-price';
import ProductPriceB from './designB/product-price';
import ProductPriceC from './designC/product-price';
export interface ProductPriceProps {
    product: ComponentProduct;
    /**
     * Renders the smaller version of the product details for smaller product components
     * @default false
     */
    compact?: boolean;

    /**
     * Show wishlist button
     */
    wishlist?: boolean;
    productDetail?: boolean;
    isDiscounted?: boolean;
    onShareClick?: () => void;
}

const ProductPrice: React.FC<ProductPriceProps> = (props) => {
    const Component = useDesignComponents<typeof ProductPriceA | typeof ProductPriceC>({
        [Design.DesignA]: ProductPriceA,
        [Design.DesignB]: ProductPriceB,
        [Design.DesignC]: ProductPriceC,
    });

    return <Component {...props} />;
};

export default ProductPrice;

export { ListPrice } from './designA/product-price';
