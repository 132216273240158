import { useDesignComponents } from '@hooks/use-design-components';
import ComponentProduct from '@models/product/component-product';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps } from '@type/common';
import { Design } from '@type/harmony-config';
import { ComponentType, ReactNode } from 'react';
import SoldOutBadgeA from './designA/sold-out-badge';
import SoldOutBadgeB from './designB/sold-out-badge';
import SoldOutBadgeC from './designC/sold-out-badge';

export interface SwipeListProductProps {
    product: ComponentProduct;
}

export interface ProductBadgesProps {
    width?: string;
    children: ReactNode;
}

export interface InternalProductBadgesProps extends ProductBadgesProps, IThemeProps {}

const SoldOutBadge: React.FC<ProductBadgesProps> = ({ children, ...props }) => {
    const { theme } = useHarmony();
    const Component = useDesignComponents<ComponentType<InternalProductBadgesProps>>({
        [Design.DesignA]: SoldOutBadgeA,
        [Design.DesignB]: SoldOutBadgeB,
        [Design.DesignC]: SoldOutBadgeC,
    });

    return (
        <Component {...props} theme={theme}>
            {children}
        </Component>
    );
};

export default SoldOutBadge;
