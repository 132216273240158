import { useDesignComponents } from '@hooks/use-design-components';

import { CustomImageProps } from 'src/legacy/components/common/custom-image';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import ImageA from './designA/product-card-image';
import ImageB from './designB/product-card-image';
import ImageC from './designC/product-card-image';

export type ProductStyleProps = {
    disabled?: boolean;
    radius?: number;
};

export interface ProductCardImageProps extends CustomImageProps, ProductStyleProps {}

const ProductCardImage: React.FC<ProductCardImageProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof ImageA | typeof ImageB | typeof ImageC>({
        [Design.DesignA]: ImageA,
        [Design.DesignB]: ImageB,
        [Design.DesignC]: ImageC,
    });
    const { theme } = useHarmony();

    return <Component {...props} theme={theme} />;
};

export default ProductCardImage;
