import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import React from 'react';
import FixedProductListContainerA from './designA/fixed-product-list-container';
import FixedProductListContainerB from './designB/fixed-product-list-container';
import FixedProductListContainerC from './designC/fixed-product-list-container';

export interface FixedProductListContainerProps {
    /**@default 2 */
    cols?: number;
    children: React.ReactNode;
}

const FixedProductListContainer: React.FC<FixedProductListContainerProps> = ({ cols, children, ...props }) => {
    const Component = useDesignComponents<typeof FixedProductListContainerA | typeof FixedProductListContainerB | typeof FixedProductListContainerC>({
        [Design.DesignA]: FixedProductListContainerA,
        [Design.DesignB]: FixedProductListContainerB,
        [Design.DesignC]: FixedProductListContainerC,
    });

    return (
        <Component cols={cols || 2} {...props}>
            {children}
        </Component>
    );
};

export default FixedProductListContainer;
