import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { ButtonStyleProps, ChevronButtonProps } from '..';

const StyledChevronButton = styled.div<IThemeProps & ButtonStyleProps & ChevronButtonProps>`
    ${({ theme, color, fontSize }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid ${theme.gray[300]};
    font-size: ${fontSize};
    color: ${theme.gray[700]};
    line-height: 1;
    cursor: pointer;
    background-color: white;
    `}
`;

export default StyledChevronButton;
