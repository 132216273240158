import { useDesignComponents } from '@hooks/use-design-components';
import ComponentProduct from '@models/product/component-product';
import { Design } from '@type/harmony-config';
import ProductCardDetailsA from './designA/product-card-details';
import ProductCardDetailsB from './designB/product-card-details';
import ProductCardDetailsC from './designC/product-card-details';

export interface ProductCardDetailsProps {
    product: ComponentProduct;
    isDiscounted?: boolean;
    cols?: number;
    centered?: boolean;
    lines?: number;
    isEllipsisBrandName?: boolean;
}

const ProductCardDetails: React.FC<ProductCardDetailsProps> = ({ isEllipsisBrandName = true, ...props }) => {
    const Component = useDesignComponents<typeof ProductCardDetailsA | typeof ProductCardDetailsB | typeof ProductCardDetailsC>({
        [Design.DesignA]: ProductCardDetailsA,
        [Design.DesignB]: ProductCardDetailsB,
        [Design.DesignC]: ProductCardDetailsC,
    });
    return <Component {...props} isEllipsisBrandName={isEllipsisBrandName} />;
};

export default ProductCardDetails;
